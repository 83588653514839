import { UnionMap, UnionValueMap } from '../types/union-map';
import { isString } from '../utilities/string';

export type FeatureFlagName =
    | 'EnableBetaDataPlane'
    | 'UnderDevelopment'
    | 'UseNonPreauthorizedScopes'
    | 'LogVerbose'
    | 'EnableThemeModeToggle'
    | 'SingleDevCenterMode'
    | 'DisplayNames'
    | 'RoundTripTime'
    | 'EnableDiscoveryService'
    | 'EnableIntrinsicTasksForCustomization'
    | 'EnableGuestUser'
    | 'EnableDevBoxRestoration';

export const FeatureFlagName: UnionMap<FeatureFlagName> = {
    EnableBetaDataPlane: 'EnableBetaDataPlane',
    UnderDevelopment: 'UnderDevelopment',
    UseNonPreauthorizedScopes: 'UseNonPreauthorizedScopes',
    LogVerbose: 'LogVerbose',
    EnableThemeModeToggle: 'EnableThemeModeToggle',
    SingleDevCenterMode: 'SingleDevCenterMode',
    DisplayNames: 'DisplayNames',
    RoundTripTime: 'RoundTripTime',
    EnableDiscoveryService: 'EnableDiscoveryService',
    EnableIntrinsicTasksForCustomization: 'EnableIntrinsicTasksForCustomization',
    EnableGuestUser: 'EnableGuestUser',
    EnableDevBoxRestoration: 'EnableDevBoxRestoration',
};

export type FeatureFlag = Lowercase<FeatureFlagName>;

export const FeatureFlag: UnionValueMap<FeatureFlagName, FeatureFlag> = {
    EnableBetaDataPlane: 'enablebetadataplane',
    UnderDevelopment: 'underdevelopment',
    UseNonPreauthorizedScopes: 'usenonpreauthorizedscopes',
    LogVerbose: 'logverbose',
    EnableThemeModeToggle: 'enablethememodetoggle',
    SingleDevCenterMode: 'singledevcentermode',
    DisplayNames: 'displaynames',
    RoundTripTime: 'roundtriptime',
    EnableDiscoveryService: 'enablediscoveryservice',
    EnableIntrinsicTasksForCustomization: 'enableintrinsictasksforcustomization',
    EnableGuestUser: 'enableguestuser',
    EnableDevBoxRestoration: 'enabledevboxrestoration',
};

export const NameForFeatureFlag: UnionValueMap<FeatureFlag, FeatureFlagName> = {
    enablebetadataplane: 'EnableBetaDataPlane',
    underdevelopment: 'UnderDevelopment',
    usenonpreauthorizedscopes: 'UseNonPreauthorizedScopes',
    logverbose: 'LogVerbose',
    enablethememodetoggle: 'EnableThemeModeToggle',
    singledevcentermode: 'SingleDevCenterMode',
    displaynames: 'DisplayNames',
    roundtriptime: 'RoundTripTime',
    enablediscoveryservice: 'EnableDiscoveryService',
    enableintrinsictasksforcustomization: 'EnableIntrinsicTasksForCustomization',
    enableguestuser: 'EnableGuestUser',
    enabledevboxrestoration: 'EnableDevBoxRestoration',
};

export const isFeatureFlag = (value: unknown): value is FeatureFlag => {
    if (!isString(value)) {
        return false;
    }

    switch (value.toLowerCase()) {
        case FeatureFlag.EnableBetaDataPlane:
        case FeatureFlag.UnderDevelopment:
        case FeatureFlag.UseNonPreauthorizedScopes:
        case FeatureFlag.LogVerbose:
        case FeatureFlag.EnableThemeModeToggle:
        case FeatureFlag.SingleDevCenterMode:
        case FeatureFlag.DisplayNames:
        case FeatureFlag.RoundTripTime:
        case FeatureFlag.EnableDiscoveryService:
        case FeatureFlag.EnableIntrinsicTasksForCustomization:
        case FeatureFlag.EnableGuestUser:
        case FeatureFlag.EnableDevBoxRestoration:
            return true;
        default:
            return false;
    }
};
