import { IIconProps } from '@fluentui/react';
import { Badge, FluentProvider, makeStyles, mergeClasses } from '@fluentui/react-components';
import {
    ArrowClockwise20Regular,
    Clock20Regular,
    Delete20Regular,
    Headset20Regular,
    History20Regular,
    Info20Regular,
    Next20Regular,
    Open20Regular,
    Play20Regular,
    Power20Regular,
    Remote20Regular,
    TaskListLtr20Regular,
    Wrench20Regular,
} from '@fluentui/react-icons';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { DevBoxFailureReason } from '../../../constants/dev-box';
import { Metric, Property, Severity } from '../../../constants/telemetry';
import devBoxCardPreviewWin11Image from '../../../content/images/dev-box-card-preview-win11.jpg';
import { useActionCreator } from '../../../hooks/action-creator';
import { useCurrentFluent2Theme } from '../../../hooks/styling';
import { CustomizationGroupStatus } from '../../../models/customization';
import { HibernateSupport } from '../../../models/pool';
import { dismissContent as dismissContentActionCreator } from '../../../redux/actions/application/application-action-creators';
import { getWelcomeTourSeenStatus } from '../../../redux/selector/application-selectors';
import { getIsUserSignedIntoMicrosoftTenant } from '../../../redux/selector/tenant-selector';
import {
    getRemoteAppOptionsSelector,
    getUseMultiMonitorSelector,
} from '../../../redux/selector/user-settings-selector';
import { DevBoxState } from '../../../redux/store/dev-box-state';
import { useHorizontalStackStyles } from '../../../themes/styles/flexbox-styles';
import { RemoteAppOption } from '../../../types/user-settings';
import { openInNewWindow, openInSameWindow, operatingSystemFamily } from '../../../utilities/browser';
import {
    getLatestCustomizationGroupFailureEndTime,
    getOverallCustomizationGroupStatus,
    isFailedCustomizationGroupStatus,
} from '../../../utilities/customization-group';
import { isNotUndefinedOrWhiteSpace } from '../../../utilities/string';
import { trackMetric, trackTrace } from '../../../utilities/telemetry/channel';
import { getTimeStringFromDate } from '../../../utilities/time';
import { tryAddMultiMonitorAndSourceParameters } from '../../../utilities/url';
import { ConfirmationDialogProperties } from '../../common/confirmation-dialog/contexts';
import { FluentIconNames } from '../../common/fluent-icon-names';
import { MetadataItemViewModel } from '../../common/metadata/models';
import { CustomizationDetailsPanelContextProperties } from '../../customization-details-panel/contexts';
import { ErrorDetailsPanelContextProperties } from '../../error-details-panel/contexts';
import ResourceCard from '../../resource-card/resource-card';
import { ResourceCardAction } from '../../resource-card/resource-card-action';
import { OpenConnectViaAppDialogProperties } from '../../user-settings/connect-via-app-dialog/contexts';
import { getIsWelcomeTourDialogDismissed } from '../../welcome-tour-dialog/selectors';
import { DelayShutdownDialogProperties } from '../delay-shutdown-dialog/contexts';
import { DevBoxDetailsPanelContextProperties } from '../dev-box-details-panel/context';
import { OSTypeMetadata, shimmeredDevBoxMetadata } from '../dev-box-metadata/dev-box-metadata';
import { DevBoxSupportPanelContextProperties } from '../dev-box-support-panel/context';
import { HibernatePreviewDialogProperties } from '../hibernate-preview-dialog/context';
import { DevBoxViewModel } from '../models';
import { RepairDialogProperties } from '../repair-dialog/context';
import { RestoreSnapshotDialogProperties } from '../restore-snapshot-dialog/contexts';
import {
    getIsConnectViaAppTeachBubbleDismissed,
    getIsConnectViaAppTeachingPopoverForWelcomeTourDismissed,
    getIsConnectViaAppTeachingPopoverForWelcomeTourSkipped,
    getIsDevboxSecondaryActionsTeachingPopoverForWelcomeTourDismissed,
} from '../selectors';
import DevBoxStateMessage from './dev-box-state-message';
import DevBoxStatusImage from './dev-box-status-image';
import { RepairOperationResultOutcome } from './models';
import {
    getAreActionsDisabled,
    getAriaLabelMessageDescriptorForOSType,
    getDevBoxCardBannerViewModel,
    getDevBoxCardMetadata,
    getIconForOSType,
    getPrimaryActions,
    getRepairDismissableContentId,
    getResourceCardError,
    getScheduleShutdownMetadataViewModel,
    getSecondaryActions,
    getUseTranslucentPreviewImage,
    shouldDisplayCustomizationFailure,
    shouldShowFirstTeachingPopoverForWelcomeTour,
    shouldShowSecondTeachingPopoverForWelcomeTour,
    showConnectViaAppTeachingBubble,
} from './selectors';
import TeachingPopoversForDevboxCard from './teaching-popovers';

interface DevBoxCardProps {
    devBox: DevBoxViewModel;
    locale: string;
    onDeleteSubmitted: (resourceId: string) => void;
    onGetRemoteConnectionSubmitted: (resourceId: string) => void;
    onOpenErrorPanel: (props: ErrorDetailsPanelContextProperties) => void;
    onOpenDevBoxDetailsPanel: (props: DevBoxDetailsPanelContextProperties) => void;
    onOpenDevBoxSupportPanel: (props: DevBoxSupportPanelContextProperties) => void;
    onStartSubmitted: (resourceId: string) => void;
    onDelayShutdownSubmitted: (devBoxId: string, delayUntil: Date) => void;
    onSkipShutdownSubmitted: (devBoxId: string) => void;
    onOpenDelayShutdownDialog: (properties: DelayShutdownDialogProperties) => void;
    onOpenRestoreSnapshotDialog: (properties: RestoreSnapshotDialogProperties) => void;
    onOpenConnectViaAppDialog: (properties: OpenConnectViaAppDialogProperties) => void;
    onOpenConfirmationDialog: (properties: ConfirmationDialogProperties) => void;
    onHibernateSubmitted: (resourceId: string) => void;
    onShutdownSubmitted: (resourceId: string) => void;
    onResumeSubmitted: (resourceId: string) => void;
    onRestartSubmitted: (resourceId: string) => void;
    onRepairSubmitted: (resourceId: string) => void;
    onRestoreSubmitted: (resourceId: string, snapshotId: string) => void;
    onOpenHibernatePreviewDialog: (props: HibernatePreviewDialogProperties) => void;
    onOpenRepairDialog: (props: RepairDialogProperties) => void;
    onOpenCustomizationPanel: (props: CustomizationDetailsPanelContextProperties) => void;
    onConnectViaAppTeachBubbleDismiss: () => void;
    onConnectViaAppTeachingPopoverForWelcomeTourDismiss: () => void;
    onDevboxSecondaryActionsTeachingPopoverForWelcomeTourDismiss: () => void;
    onConnectViaAppTeachingPopoverForWelcomeTourSkipped: () => void;
    shouldShowConnectViaAppTeachBubble: boolean;
    shouldShowConnectViaAppTeachingPopoverForWelcomeTour: boolean;
    shouldShowDevboxSecondaryActionsTeachingPopoverForWelcomeTour: boolean;
    isHibernatePreviewDialogDismissed: boolean;
}

const messages = defineMessages({
    deleteDialogPrimaryButtonAriaLabel: {
        id: 'DevBoxCard_DeleteDialogPrimaryButton_AriaLabel',
        defaultMessage: 'Delete the dev box',
        description: 'Aria label for the submit button to confirm deleting the dev box',
    },
    deleteDialogPrimaryButtonText: {
        id: 'DevBoxCard_DeleteDialogPrimaryButton_Text',
        defaultMessage: 'Delete',
        description: 'Confirmation button to delete the dev box',
    },
    deleteDialogSubText: {
        id: 'DevBoxCard_DeleteDialogSubText_Text',
        defaultMessage:
            'This workstation and all its data will be permanently removed. Are you sure you want to delete {name}?',
        description:
            'Informs the user about the effects of deleting the dev box and confirms. {name} should not be localized, it is the name of the dev box.',
    },
    deleteDialogTitle: {
        id: 'DevBoxCard_DeleteDialogTitle_Text',
        defaultMessage: 'Delete dev box',
        description: 'Title text for "Delete dev box" dialog',
    },
    deleteMenuItemAriaLabel: {
        id: 'DevBoxCard_DeleteMenuItem_AriaLabel',
        defaultMessage: 'Delete dev box',
        description: 'Aria label for "Delete" contextual menu item in dev box card\'s settings button.',
    },
    delayShutdownMenuItemAriaLabel: {
        id: 'DevBoxCard_DelayMenuItem_AriaLabel',
        defaultMessage: 'Delay shutdown for dev box',
        description: 'Aria label for "Delay shutdown" contextual menu item in dev box card\'s settings button.',
    },
    restoreMenuItemAriaLabel: {
        id: 'DevBoxCard_RestoreMenuItem_AriaLabel',
        defaultMessage: 'Restore',
        description: 'Text for "Restore" contextual menu item in dev box card\'s settings button.',
    },
    openInBrowserMenuItemAriaLabel: {
        id: 'DevBoxCard_OpenInBrowserMenuItem_AriaLabel',
        defaultMessage: 'Open in browser',
        description: 'Aria label for "Open in Browser" button in split button.',
    },
    connectViaAppMenuItemAriaLabel: {
        id: 'DevBoxCard_ConnectViaAppMenuItem_AriaLabel',
        defaultMessage: 'Connect via app',
        description: 'Aria label for "Connect via app" option in the open split button for dev box cards',
    },
    startItemAriaLabel: {
        id: 'DevBoxCard_StartMenuItem_AriaLabel',
        defaultMessage: 'Start dev box',
        description: 'Aria label for "Start" contextual menu item in dev box card\'s settings button.',
    },
    devBoxDetailsItemAriaLabel: {
        id: 'DevBoxCard_DevBoxDetailsMenuItem_AriaLabel',
        defaultMessage: 'More info',
        description: 'Aria label for "More Info" contextual menu item in dev box card\'s settings button.',
    },
    devBoxSupportItemAriaLabel: {
        id: 'DevBoxCard_DevBoxSupportMenuItem_AriaLabel',
        defaultMessage: 'Support',
        description: 'Aria label for "Support" contextual menu item in dev box card\'s settings button.',
    },
    hibernateMenuItemAriaLabel: {
        id: 'DevBoxCard_HibernateMenuItem_AriaLabel',
        defaultMessage: 'Hibernate',
        description: 'Text for "Hibernate" contextual menu item in dev box card\'s settings button.',
    },
    resumeMenuItemAriaLabel: {
        id: 'DevBoxCard_ResumeMenuItem_AriaLabel',
        defaultMessage: 'Resume',
        description: 'Text for "Resume" contextual menu item in dev box card\'s settings button.',
    },
    shutdownMenuItemAriaLabel: {
        id: 'DevBoxCard_ShutdownMenuItem_AriaLabel',
        defaultMessage: 'Shut down',
        description: 'Text for "Shut down" contextual menu item in dev box card\'s settings button.',
    },
    restartItemAriaLabel: {
        id: 'DevBoxCard_RestartMenuItem_AriaLabel',
        defaultMessage: 'Restart dev box',
        description: 'Aria label for "Restart" contextual menu item in dev box card\'s settings button.',
    },
    repairItemAriaLabel: {
        id: 'DevBoxCard_RepairMenuItem_AriaLabel',
        defaultMessage: 'Troubleshoot & repair dev box',
        description: 'Aria label for "Troubleshoot & repair" contextual menu item in dev box card\'s settings button.',
    },
    spinnerAriaLabel: {
        id: 'DevBoxCard_Spinner_AriaLabel',
        defaultMessage: 'In progress operation on your dev box',
        description: 'Aria label for spinner tracking progress of dev box operation in dev box card',
    },
    customizationsItemAriaLabel: {
        id: 'DevBoxCard_CustomizationsMenuItem_AriaLabel',
        defaultMessage: 'Customizations',
        description: 'Aria label for "Customizations" contextual menu item in dev box card\'s settings button.',
    },
    connectViaAppTeachBubble: {
        id: 'DevBoxCard_ConnectViaAppTeachBubble_Title',
        defaultMessage: "We've added a new way to connect",
        description: 'Title for connect-via-app teach bubble',
    },
    connectViaAppTeachingPopover_Footer_Primary_Button: {
        id: 'DevBoxCard_ConnectViaAppTeachingPopover_Footer_Primary_Button',
        defaultMessage: 'Next tip',
        description: 'Text for the connect-via-app teaching popover footer primary button',
    },
    connectViaAppTeachingPopover_Footer_Secondary_Button: {
        id: 'DevBoxCard_ConnectViaAppTeachingPopover_Footer_Secondary_Button',
        defaultMessage: 'Skip',
        description: 'Text for the connect-via-app teaching popover footer secondary button',
    },
    buttonAriaLabel: {
        id: 'DevBoxCard_SecondaryActionsButton_AriaLabel',
        defaultMessage: '{resourceName} actions',
        description: 'Aria label for actions button.',
    },
});

/**
 * Styles
 */

const useBadgeStyles = makeStyles({
    root: {
        width: '100%',
        justifyContent: 'space-between',
    },
});

/**
 * END Styles
 */

export const DevBoxCard: React.FC<DevBoxCardProps> = React.memo((props: DevBoxCardProps) => {
    const {
        devBox,
        locale,
        onDeleteSubmitted,
        onGetRemoteConnectionSubmitted,
        onOpenErrorPanel,
        onOpenDevBoxDetailsPanel,
        onStartSubmitted,
        onDelayShutdownSubmitted,
        onSkipShutdownSubmitted,
        onOpenDelayShutdownDialog,
        onOpenConnectViaAppDialog,
        onOpenConfirmationDialog,
        onHibernateSubmitted,
        onShutdownSubmitted,
        onResumeSubmitted,
        onRestartSubmitted,
        onRepairSubmitted,
        onRestoreSubmitted,
        onOpenHibernatePreviewDialog,
        onOpenRepairDialog,
        onOpenRestoreSnapshotDialog,
        onOpenCustomizationPanel,
        onOpenDevBoxSupportPanel,
        onConnectViaAppTeachBubbleDismiss,
        onConnectViaAppTeachingPopoverForWelcomeTourDismiss,
        onDevboxSecondaryActionsTeachingPopoverForWelcomeTourDismiss,
        onConnectViaAppTeachingPopoverForWelcomeTourSkipped,
        shouldShowConnectViaAppTeachBubble,
        shouldShowConnectViaAppTeachingPopoverForWelcomeTour,
        shouldShowDevboxSecondaryActionsTeachingPopoverForWelcomeTour,
        isHibernatePreviewDialogDismissed,
    } = props;

    const {
        remoteDesktopUrl,
        cloudPcConnectionUrl,
        resource,
        state,
        webUrl,
        isCardContentReady,
        devBoxActionState,
        customizationGroup,
        customizationGroups,
        repairOperation,
        failureOnDevBox,
        customizationFailure,
        pool,
        projectDisplayName,
        snapshots,
    } = devBox;

    const { result: repairOperationResult } = repairOperation ?? {};

    const customizationGroupStatus = React.useMemo(
        () => getOverallCustomizationGroupStatus(customizationGroups),
        [customizationGroups]
    );

    const latestCustomizationGroupFailureEndTime = React.useMemo(
        () => getLatestCustomizationGroupFailureEndTime(customizationGroups),
        [customizationGroups]
    );

    const userRemoteAppOption = useSelector(getRemoteAppOptionsSelector);
    const userUseMultiMonitor = useSelector(getUseMultiMonitorSelector);
    const isConnectViaAppTeachBubbleDismissed = useSelector(getIsConnectViaAppTeachBubbleDismissed);
    const isConnectViaAppTeachingPopoverForWelcomeTourDismissed = useSelector(
        getIsConnectViaAppTeachingPopoverForWelcomeTourDismissed
    );

    const isDevboxSecondaryActionsTeachingPopoverForWelcomeTourDismissed = useSelector(
        getIsDevboxSecondaryActionsTeachingPopoverForWelcomeTourDismissed
    );

    const isConnectViaAppTeachingPopoverForWelcomeTourSkipped = useSelector(
        getIsConnectViaAppTeachingPopoverForWelcomeTourSkipped
    );

    const {
        name,
        projectName: projectResourceName,
        poolName,
        location,
        createdTime,
        osType,
        imageReference,
        hardwareProfile,
        storageProfile,
        hibernateSupport,
        uri,
        uniqueId,
    } = resource;

    const { originalScheduledStopTime, nextScheduledStopTime, skipState, delayState } = devBoxActionState;

    // Theme hooks
    const theme = useCurrentFluent2Theme();

    // Styling hooks
    const badgeStyles = useBadgeStyles();
    const horizontalStackStyles = useHorizontalStackStyles();

    // Intl hooks
    const { formatMessage } = useIntl();

    // Note: putting these memoizers here since they're used within callbacks
    const hasHibernateSupport = hibernateSupport === HibernateSupport.Enabled;

    // Connect via app teach bubble element to anchor the bubble to
    const connectViaAppMenuItemAriaLabel = React.useMemo(() => {
        return `[aria-label="${formatMessage(messages.connectViaAppMenuItemAriaLabel)}"]`;
    }, [formatMessage]);

    const connectViaAppTeachBubbleTargetElement = document.querySelector(connectViaAppMenuItemAriaLabel);

    // Dev box secondary actions teach popover element to anchor the popover to
    const devBoxCardSecondaryActionsAriaLabel = React.useMemo(() => {
        return `[aria-label="${formatMessage(messages.buttonAriaLabel, { resourceName: name })}"]`;
    }, [formatMessage, name]);

    const devBoxCardSecondaryActionsTargetElement = document.querySelector(devBoxCardSecondaryActionsAriaLabel);

    const resourceCardError = React.useMemo(
        () =>
            getResourceCardError(
                failureOnDevBox,
                state,
                hasHibernateSupport,
                devBoxActionState,
                repairOperationResult,
                customizationGroupStatus,
                customizationFailure,
                latestCustomizationGroupFailureEndTime
            ),
        [
            failureOnDevBox,
            state,
            devBoxActionState,
            hasHibernateSupport,
            repairOperationResult,
            customizationGroupStatus,
            customizationFailure,
            latestCustomizationGroupFailureEndTime,
        ]
    );

    // If there are issues detected but could not be automatically fixed by repair operation, it is considered a failure
    const showSeeDetailsButton =
        !!resourceCardError?.failure ||
        repairOperationResult?.repairOutcome === RepairOperationResultOutcome.IssuesDetected ||
        shouldDisplayCustomizationFailure(customizationGroupStatus, latestCustomizationGroupFailureEndTime);

    const repairDismissableContentId = React.useMemo(
        () => getRepairDismissableContentId(uri, repairOperation?.operationId),
        [uri, repairOperation?.operationId]
    );

    const infoBannerIconProps: IIconProps = React.useMemo(
        () => ({
            iconName:
                state === DevBoxState.Repairing ||
                state === DevBoxState.Restoring ||
                customizationGroupStatus === CustomizationGroupStatus.Running ||
                customizationGroupStatus === CustomizationGroupStatus.NotStarted
                    ? undefined
                    : FluentIconNames.Info,
        }),
        [state, customizationGroupStatus]
    );

    const scheduledStopTimeString = React.useMemo(
        () =>
            originalScheduledStopTime !== undefined
                ? getTimeStringFromDate(originalScheduledStopTime, locale)
                : undefined,
        [originalScheduledStopTime, locale]
    );

    const projectName = React.useMemo(
        () => (isNotUndefinedOrWhiteSpace(projectDisplayName) ? projectDisplayName : projectResourceName),
        [projectDisplayName, projectResourceName]
    );

    const isShimmered = !isCardContentReady;

    const dismissContent = useActionCreator(dismissContentActionCreator);

    // Callback hooks
    const onDismiss = React.useCallback(() => {
        if (repairDismissableContentId) {
            dismissContent({ content: repairDismissableContentId });
        }
    }, [repairDismissableContentId, dismissContent]);

    const onDeleteDialogPrimaryButtonClicked = React.useCallback(() => {
        onDeleteSubmitted(uri);
    }, [onDeleteSubmitted, uri]);

    const onDevBoxDetailsMenuItemClicked = React.useCallback(() => {
        const props: DevBoxDetailsPanelContextProperties = {
            name,
            createdOn: createdTime,
            poolName,
            location,
            imageReference,
            hardwareProfile,
            storageProfile,
            scheduledStopTime: scheduledStopTimeString,
            hibernateSupport: hibernateSupport ?? HibernateSupport.Disabled,
        };

        onOpenDevBoxDetailsPanel(props);
    }, [
        name,
        createdTime,
        poolName,
        location,
        imageReference,
        hardwareProfile,
        storageProfile,
        scheduledStopTimeString,
        onOpenDevBoxDetailsPanel,
        hibernateSupport,
    ]);

    const onDevBoxSupportMenuItemClicked = React.useCallback(() => {
        const props: DevBoxSupportPanelContextProperties = {
            devBoxUniqueID: uniqueId ?? '',
            poolName,
            poolDisplayName: pool?.displayName,
            devBoxName: name,
            poolId: pool?.uri,
        };
        onOpenDevBoxSupportPanel(props);
    }, [uniqueId, name, pool?.displayName, poolName, pool?.uri]);

    const onOpenInBrowserSubmitted = React.useCallback(() => {
        if (isNotUndefinedOrWhiteSpace(webUrl)) {
            openInNewWindow(webUrl);
        }
    }, [webUrl]);

    const onOpenInRdpClientSubmitted = React.useCallback(() => {
        const useWindowsApp = userRemoteAppOption === RemoteAppOption.useWindowsApp;
        const remoteDesktopConnection = useWindowsApp ? cloudPcConnectionUrl : remoteDesktopUrl;
        if (isNotUndefinedOrWhiteSpace(remoteDesktopConnection)) {
            /**
             * Update the remote connection uri formatting based on user settings.
             * We need to add the usemultimon parameter to the uri and set the source from devportal.
             */
            const updatedRemoteDesktopConnection = tryAddMultiMonitorAndSourceParameters(
                remoteDesktopConnection,
                userUseMultiMonitor
            );

            if (isNotUndefinedOrWhiteSpace(updatedRemoteDesktopConnection)) {
                if (userRemoteAppOption) {
                    openInSameWindow(updatedRemoteDesktopConnection);
                    trackMetric(Metric.DirectLaunchStarted, 1, {
                        properties: {
                            [Property.MultiMonitor]: userUseMultiMonitor,
                            [Property.PreferredRemoteApp]: userRemoteAppOption,
                        },
                    });
                } else {
                    onOpenConnectViaAppDialog({ cloudPcConnectionUrl, remoteDesktopUrl });
                }
            }
        }
    }, [onOpenConnectViaAppDialog, remoteDesktopUrl, cloudPcConnectionUrl, userRemoteAppOption, userUseMultiMonitor]);

    const devBoxStateMessageComponent = <DevBoxStateMessage state={state} />;
    const devBoxStatusImageElement = <DevBoxStatusImage state={state} />;

    const onRetryFailedOperation = React.useCallback(() => {
        const { reason } = failureOnDevBox ?? {};

        if (!reason) {
            return;
        }

        switch (reason) {
            case DevBoxFailureReason.DeleteFailed:
                onDeleteSubmitted(uri);
                return;
            case DevBoxFailureReason.GetRemoteConnectionFailed:
                onGetRemoteConnectionSubmitted(uri);
                return;
            case DevBoxFailureReason.HibernateFailed:
                onHibernateSubmitted(uri);
                return;
            case DevBoxFailureReason.RepairFailed:
                onRepairSubmitted(uri);
                return;
            case DevBoxFailureReason.RestartFailed:
                onRestartSubmitted(uri);
                return;
            case DevBoxFailureReason.ResumeFailed:
                onResumeSubmitted(uri);
                return;
            case DevBoxFailureReason.ShutdownFailed:
                onShutdownSubmitted(uri);
                return;
            case DevBoxFailureReason.StartFailed:
                onStartSubmitted(uri);
                return;
            default:
                trackTrace(`Invalid state: retry not currently supported after failure reason "${reason}".`, {
                    severity: Severity.Warning,
                });
                return;
        }
    }, [
        failureOnDevBox?.reason,
        onDeleteSubmitted,
        onGetRemoteConnectionSubmitted,
        onStartSubmitted,
        onHibernateSubmitted,
        onShutdownSubmitted,
        uri,
    ]);

    const onSeeErrorDetailsClicked = React.useCallback(() => {
        if (!showSeeDetailsButton) {
            trackTrace('Invalid state: error details requested, but failure message is falsy or white space.', {
                severity: Severity.Warning,
            });
            return;
        }

        // Makes sure we open customization details panel on customization group failure
        if (isFailedCustomizationGroupStatus(customizationGroupStatus) && customizationGroups && customizationGroup) {
            onOpenCustomizationPanel({ customizationGroup, customizationGroups, devBoxName: name });
            return;
        }

        onOpenErrorPanel({ failure: resourceCardError?.failure, devBoxName: name, repairOperationResult });
    }, [
        resourceCardError,
        name,
        showSeeDetailsButton,
        customizationGroupStatus,
        customizationGroup,
        customizationGroups,
        onOpenErrorPanel,
        onOpenCustomizationPanel,
    ]);

    const onSeeCustomizationDetailsClicked = React.useCallback(() => {
        if (!customizationGroups || !customizationGroup) {
            trackTrace(
                'Unexpected state: tried to open customization group details, but no customizationGroup was defined.',
                { severity: Severity.Warning }
            );
            return;
        }

        onOpenCustomizationPanel({
            customizationGroup,
            customizationGroups,
            devBoxName: name,
        });
    }, [name, customizationGroup, customizationGroups, onOpenCustomizationPanel]);

    const onStartMenuItemClicked = React.useCallback(() => {
        onStartSubmitted(uri);
    }, [onStartSubmitted, uri]);

    const onShutdownMenuItemClicked = React.useCallback(() => {
        onShutdownSubmitted(uri);
    }, [onShutdownSubmitted, uri]);

    const onHibernateDialogSubmitted = React.useCallback(() => {
        onHibernateSubmitted(uri);
    }, [onHibernateSubmitted, uri]);

    const onHibernateMenuItemClicked = React.useCallback(() => {
        if (isHibernatePreviewDialogDismissed) {
            onHibernateSubmitted(uri);
        } else {
            onOpenHibernatePreviewDialog({ devBoxName: name, onHibernateSubmitted: onHibernateDialogSubmitted });
        }
    }, [
        onOpenHibernatePreviewDialog,
        onHibernateDialogSubmitted,
        name,
        onHibernateSubmitted,
        isHibernatePreviewDialogDismissed,
        uri,
    ]);

    const onResumeMenuItemClicked = React.useCallback(() => {
        onResumeSubmitted(uri);
    }, [onResumeSubmitted, uri]);

    const onRestartMenuItemClicked = React.useCallback(() => {
        onRestartSubmitted(uri);
    }, [onRestartSubmitted, uri]);

    const onRepairDialogSubmitted = React.useCallback(() => {
        onRepairSubmitted(uri);
    }, [onRepairSubmitted, uri]);

    const onRepairMenuItemClicked = React.useCallback(() => {
        onOpenRepairDialog({ onRepairSubmitted: onRepairDialogSubmitted });
    }, [onOpenRepairDialog, onRepairDialogSubmitted]);

    // We need both these values to calculate options for the skip / delay form
    const canDelayShutdown = !!nextScheduledStopTime && !!originalScheduledStopTime;

    const onRestoreMenuItemClicked = React.useCallback(() => {
        onOpenRestoreSnapshotDialog({
            devBoxId: uri,
            snapshots,
            locale,
            onRestoreSubmitted,
        });
    }, [onOpenRestoreSnapshotDialog, uri, locale, snapshots, onRestoreSubmitted]);

    const onDelayShutdownMenuItemClicked = React.useCallback(() => {
        if (canDelayShutdown) {
            onOpenDelayShutdownDialog({
                devBoxId: uri,
                locale,
                originalScheduledTime: originalScheduledStopTime,
                nextScheduledTime: nextScheduledStopTime,
                skipState,
                delayState,
                onDelaySubmitted: onDelayShutdownSubmitted,
                onSkipSubmitted: onSkipShutdownSubmitted,
                hasHibernateSupport,
            });
        }
    }, [
        onOpenDelayShutdownDialog,
        uri,
        locale,
        originalScheduledStopTime,
        nextScheduledStopTime,
        skipState,
        delayState,
        onDelayShutdownSubmitted,
        onSkipShutdownSubmitted,
        canDelayShutdown,
    ]);

    // Memoized data
    const arePrimaryActionsDisabled = React.useMemo(() => getAreActionsDisabled(state), [state]);
    const ariaLabelForOSType = React.useMemo(() => getAriaLabelMessageDescriptorForOSType(osType), [osType]);
    const deleteDialogSubTextValues = React.useMemo(() => ({ name }), [name]);
    const iconForOSType = React.useMemo(() => getIconForOSType(osType), [osType]);
    const useTranslucentPreviewImage = React.useMemo(() => getUseTranslucentPreviewImage(state), [state]);
    const isInMicrosoftTenant = useSelector(getIsUserSignedIntoMicrosoftTenant);

    const onDeleteMenuItemClicked = React.useCallback(() => {
        onOpenConfirmationDialog({
            onPrimaryButtonSubmitted: onDeleteDialogPrimaryButtonClicked,
            primaryButtonAriaLabel: formatMessage(messages.deleteDialogPrimaryButtonAriaLabel),
            primaryButtonText: formatMessage(messages.deleteDialogPrimaryButtonText),
            subText: formatMessage(messages.deleteDialogSubText, deleteDialogSubTextValues),
            title: formatMessage(messages.deleteDialogTitle),
        });
    }, [onOpenConfirmationDialog, onDeleteDialogPrimaryButtonClicked, deleteDialogSubTextValues]);

    // Resource card actions
    const deleteMenuItem = React.useMemo(
        () => (
            <ResourceCardAction
                ariaLabel={formatMessage(messages.deleteMenuItemAriaLabel)}
                onClick={onDeleteMenuItemClicked}
                icon={<Delete20Regular />}
                key="delete"
            >
                <FormattedMessage
                    id="DevBoxCard_DeleteMenuItem_Text"
                    defaultMessage="Delete"
                    description='Text for "Delete" contextual menu item in dev box cards settings button.'
                />
            </ResourceCardAction>
        ),
        [formatMessage, onDeleteMenuItemClicked]
    );

    const openInBrowserMenuItem = React.useMemo(
        () => (
            <ResourceCardAction
                ariaLabel={formatMessage(messages.openInBrowserMenuItemAriaLabel)}
                onClick={onOpenInBrowserSubmitted}
                icon={<Open20Regular />}
                key="open-in-browser"
            >
                <FormattedMessage
                    id="DevBoxCard_OpenInBrowserMenuItem_Text"
                    defaultMessage="Open in browser"
                    description='Text for "Open in Browser" button in dev box card.'
                />
            </ResourceCardAction>
        ),
        [formatMessage, onOpenInBrowserSubmitted]
    );

    const openInRdpClientMenuItem = React.useMemo(
        () => (
            <ResourceCardAction
                ariaLabel={formatMessage(messages.connectViaAppMenuItemAriaLabel)}
                onClick={onOpenInRdpClientSubmitted}
                icon={<Remote20Regular />}
                key="connect-via-app"
            >
                <FormattedMessage
                    id="DevBoxCard_ConnectViaAppMenuItem_Text"
                    defaultMessage="Connect via app"
                    description='Text for "Connect via app" option in the open split button for dev box cards'
                />
            </ResourceCardAction>
        ),
        [formatMessage, onOpenInRdpClientSubmitted]
    );

    const startMenuItem = React.useMemo(
        () => (
            <ResourceCardAction
                ariaLabel={formatMessage(messages.startItemAriaLabel)}
                onClick={onStartMenuItemClicked}
                icon={<Play20Regular />}
                key="start"
            >
                <FormattedMessage
                    id="DevBoxCard_StartMenuItem_Text"
                    defaultMessage="Start"
                    description='Text for "Start" contextual menu item in dev box cards settings button.'
                />
            </ResourceCardAction>
        ),
        [formatMessage, onStartMenuItemClicked]
    );

    const shutdownMenuItem = React.useMemo(
        () => (
            <ResourceCardAction
                ariaLabel={formatMessage(messages.shutdownMenuItemAriaLabel)}
                onClick={onShutdownMenuItemClicked}
                icon={<Power20Regular />}
                key="shutdown"
            >
                <FormattedMessage
                    id="DevBoxCard_ShutdownMenuItem_Text"
                    defaultMessage="Shut down"
                    description='Text for "Shut down" contextual menu item in dev box cards settings button.'
                />
            </ResourceCardAction>
        ),
        [formatMessage, onShutdownMenuItemClicked]
    );

    const delayShutdownMenuItem = React.useMemo(
        () => (
            <ResourceCardAction
                ariaLabel={formatMessage(messages.delayShutdownMenuItemAriaLabel)}
                onClick={onDelayShutdownMenuItemClicked}
                icon={<Next20Regular />}
                key="delay"
            >
                <FormattedMessage
                    id="DevBoxCard_DelayShutdownMenuItem_Text"
                    defaultMessage="Delay scheduled shutdown"
                    description='Text for "Delay scheduled shutdown" contextual menu item in dev box cards settings button.'
                />
            </ResourceCardAction>
        ),
        [formatMessage, onDelayShutdownMenuItemClicked]
    );

    const restoreMenuItem = React.useMemo(
        () => (
            <ResourceCardAction
                ariaLabel={formatMessage(messages.restoreMenuItemAriaLabel)}
                onClick={onRestoreMenuItemClicked}
                icon={<History20Regular />}
                key="restore"
            >
                <FormattedMessage
                    id="DevBoxCard_RestoreMenuItem_Text"
                    defaultMessage="Restore"
                    description='Text for "Restore" contextual menu item in dev box cards settings button.'
                />
            </ResourceCardAction>
        ),
        [formatMessage, onRestoreMenuItemClicked]
    );

    const hibernateMenuItem = React.useMemo(
        () => (
            <ResourceCardAction
                ariaLabel={formatMessage(messages.hibernateMenuItemAriaLabel)}
                onClick={onHibernateMenuItemClicked}
                icon={<Clock20Regular />}
                key="hibernate"
            >
                <div className={mergeClasses(horizontalStackStyles.root, badgeStyles.root)}>
                    <div className={horizontalStackStyles.item}>
                        <FormattedMessage
                            id="DevBoxCard_HibernateMenuItem_Text"
                            defaultMessage="Hibernate"
                            description='Text for "Hibernate" contextual menu item in dev box cards settings button.'
                        />
                    </div>
                    <div className={horizontalStackStyles.item}>
                        <Badge appearance="outline" color="informative" size="medium">
                            <FormattedMessage
                                id="HibernateDialog_Preview_Badge"
                                defaultMessage="Preview"
                                description="Tag used to indicate that a feature is in preview"
                            />
                        </Badge>
                    </div>
                </div>
            </ResourceCardAction>
        ),
        [formatMessage, onHibernateMenuItemClicked]
    );

    const resumeMenuItem = React.useMemo(
        () => (
            <ResourceCardAction
                ariaLabel={formatMessage(messages.resumeMenuItemAriaLabel)}
                onClick={onResumeMenuItemClicked}
                icon={<Play20Regular />}
                key="resume"
            >
                <FormattedMessage
                    id="DevBoxCard_ResumeMenuItem_Text"
                    defaultMessage="Resume"
                    description='Text for "Resume" contextual menu item in dev box cards settings button.'
                />
            </ResourceCardAction>
        ),
        [formatMessage, onResumeMenuItemClicked]
    );

    const restartMenuItem = React.useMemo(
        () => (
            <ResourceCardAction
                ariaLabel={formatMessage(messages.restartItemAriaLabel)}
                onClick={onRestartMenuItemClicked}
                icon={<ArrowClockwise20Regular />}
                key="restart"
            >
                <FormattedMessage
                    id="DevBoxCard_RestartMenuItem_Text"
                    defaultMessage="Restart"
                    description='Text for "Restart" contextual menu item in dev box cards settings button'
                />
            </ResourceCardAction>
        ),
        [formatMessage, onRestartMenuItemClicked]
    );

    const devBoxDetailsMenuItem = React.useMemo(
        () => (
            <ResourceCardAction
                ariaLabel={formatMessage(messages.devBoxDetailsItemAriaLabel)}
                onClick={onDevBoxDetailsMenuItemClicked}
                icon={<Info20Regular />}
                key="more-info"
            >
                <FormattedMessage
                    id="DevBoxCard_DevBoxDetailsItem_Text"
                    defaultMessage="More Info"
                    description='Text for "More Info" contextual menu item in dev box cards settings button.'
                />
            </ResourceCardAction>
        ),
        [formatMessage, onDevBoxDetailsMenuItemClicked]
    );

    const devBoxSupportMenuItem = React.useMemo(
        () => (
            <ResourceCardAction
                ariaLabel={formatMessage(messages.devBoxSupportItemAriaLabel)}
                onClick={onDevBoxSupportMenuItemClicked}
                icon={<Headset20Regular />}
                key="support"
            >
                <FormattedMessage
                    id="DevBoxCard_DevBoxSupportMenuItem_Text"
                    defaultMessage="Support"
                    description='Text for "Support" contextual menu item in dev box cards settings button.'
                />
            </ResourceCardAction>
        ),
        [formatMessage, onDevBoxSupportMenuItemClicked]
    );

    const repairMenuItem = React.useMemo(
        () => (
            <ResourceCardAction
                ariaLabel={formatMessage(messages.repairItemAriaLabel)}
                onClick={onRepairMenuItemClicked}
                icon={<Wrench20Regular />}
                key="repair"
            >
                <FormattedMessage
                    id="DevBoxCard_RepairMenuItem_Text"
                    defaultMessage="Troubleshoot & repair"
                    description='Text for "Troubleshoot & repair" contextual menu item in dev box cards settings button'
                />
            </ResourceCardAction>
        ),
        [formatMessage, onRepairMenuItemClicked]
    );

    const customizeMenuItem = React.useMemo(
        () => (
            <ResourceCardAction
                ariaLabel={formatMessage(messages.customizationsItemAriaLabel)}
                onClick={onSeeCustomizationDetailsClicked}
                icon={<TaskListLtr20Regular />}
                key="customization"
            >
                <FormattedMessage
                    id="DevBoxCard_CustomizationsMenuItem_Text"
                    defaultMessage="Customizations"
                    description='Text for "Customizations" contextual menu item in dev box cards settings button'
                />
            </ResourceCardAction>
        ),
        [formatMessage, onSeeCustomizationDetailsClicked]
    );

    const primaryActions = React.useMemo(
        () => getPrimaryActions(openInBrowserMenuItem, openInRdpClientMenuItem, operatingSystemFamily, state),
        [openInBrowserMenuItem, openInRdpClientMenuItem, state]
    );

    const hasCustomizationGroup = !!customizationGroups;

    const secondaryActions = React.useMemo(
        () =>
            getSecondaryActions(
                deleteMenuItem,
                startMenuItem,
                state,
                devBoxDetailsMenuItem,
                canDelayShutdown,
                delayShutdownMenuItem,
                restoreMenuItem,
                hasHibernateSupport,
                hibernateMenuItem,
                resumeMenuItem,
                shutdownMenuItem,
                restartMenuItem,
                repairMenuItem,
                customizeMenuItem,
                hasCustomizationGroup,
                devBoxSupportMenuItem,
                isInMicrosoftTenant
            ),
        [
            deleteMenuItem,
            startMenuItem,
            state,
            devBoxDetailsMenuItem,
            delayShutdownMenuItem,
            restoreMenuItem,
            canDelayShutdown,
            hasHibernateSupport,
            hibernateMenuItem,
            resumeMenuItem,
            shutdownMenuItem,
            restartMenuItem,
            repairMenuItem,
            customizeMenuItem,
            hasCustomizationGroup,
            devBoxSupportMenuItem,
            isInMicrosoftTenant,
        ]
    );

    // Resource card metadata
    const osTypeMetadata: MetadataItemViewModel = React.useMemo(
        () => ({
            description: formatMessage(ariaLabelForOSType),
            icon: iconForOSType,
            key: 'os-type',
            primary: true,
            value: <OSTypeMetadata osType={osType} />,
        }),
        [ariaLabelForOSType, iconForOSType, osType, formatMessage]
    );

    const scheduleMetadata: MetadataItemViewModel | undefined = React.useMemo(
        () => getScheduleShutdownMetadataViewModel(locale, formatMessage, nextScheduledStopTime, hasHibernateSupport),
        [nextScheduledStopTime, locale, formatMessage, hasHibernateSupport]
    );

    const metadata = React.useMemo(
        () => getDevBoxCardMetadata(osTypeMetadata, state, scheduleMetadata),
        [osTypeMetadata, scheduleMetadata, state]
    );

    const cardBanner = React.useMemo(
        () =>
            getDevBoxCardBannerViewModel(
                devBox,
                locale,
                infoBannerIconProps,
                formatMessage(messages.spinnerAriaLabel),
                resourceCardError,
                onRetryFailedOperation,
                showSeeDetailsButton ? onSeeErrorDetailsClicked : undefined,
                customizationGroups ? onSeeCustomizationDetailsClicked : undefined,
                onDismiss
            ),
        [
            devBox,
            locale,
            infoBannerIconProps,
            formatMessage,
            onDismiss,
            resourceCardError,
            onRetryFailedOperation,
            onSeeErrorDetailsClicked,
            showSeeDetailsButton,
            customizationGroups,
            onSeeCustomizationDetailsClicked,
        ]
    );

    const isWelcomeTourSeenOrSkipped = useSelector(getWelcomeTourSeenStatus);

    // This means the tour was atleast partially seen
    const isWelcomeTourDialogDismissed = useSelector(getIsWelcomeTourDialogDismissed);

    const shouldShowFirstTeachingPopover = shouldShowFirstTeachingPopoverForWelcomeTour(
        shouldShowConnectViaAppTeachingPopoverForWelcomeTour,
        isConnectViaAppTeachingPopoverForWelcomeTourDismissed,
        isConnectViaAppTeachingPopoverForWelcomeTourSkipped,
        isCardContentReady,
        connectViaAppTeachBubbleTargetElement,
        isWelcomeTourSeenOrSkipped,
        isWelcomeTourDialogDismissed
    );

    const shouldShowSecondTeachingPopover = shouldShowSecondTeachingPopoverForWelcomeTour(
        shouldShowFirstTeachingPopover,
        shouldShowDevboxSecondaryActionsTeachingPopoverForWelcomeTour,
        isDevboxSecondaryActionsTeachingPopoverForWelcomeTourDismissed,
        devBoxCardSecondaryActionsTargetElement,
        isCardContentReady,
        isWelcomeTourSeenOrSkipped,
        isWelcomeTourDialogDismissed
    );

    const shouldShowConnectViaAppTeachingBubble = showConnectViaAppTeachingBubble(
        isCardContentReady,
        shouldShowConnectViaAppTeachBubble,
        isConnectViaAppTeachBubbleDismissed,
        connectViaAppTeachBubbleTargetElement,
        isWelcomeTourSeenOrSkipped,
        isWelcomeTourDialogDismissed
    );

    return (
        <FluentProvider theme={theme}>
            <TeachingPopoversForDevboxCard
                shouldShowFirstTeachingPopoverForWelcomeTour={shouldShowFirstTeachingPopover}
                shouldShowSecondTeachingPopoverForWelcomeTour={shouldShowSecondTeachingPopover}
                shouldShowConnectViaAppTeachingBubble={shouldShowConnectViaAppTeachingBubble}
                onConnectViaAppTeachBubbleDismiss={onConnectViaAppTeachBubbleDismiss}
                onConnectViaAppTeachingPopoverForWelcomeTourDismiss={
                    onConnectViaAppTeachingPopoverForWelcomeTourDismiss
                }
                onDevboxSecondaryActionsTeachingPopoverForWelcomeTourDismiss={
                    onDevboxSecondaryActionsTeachingPopoverForWelcomeTourDismiss
                }
                onConnectViaAppTeachingPopoverForWelcomeTourSkip={onConnectViaAppTeachingPopoverForWelcomeTourSkipped}
                devBoxCardSecondaryActionsTargetElement={devBoxCardSecondaryActionsTargetElement}
                connectViaAppTeachBubbleTargetElement={connectViaAppTeachBubbleTargetElement}
            />
            <ResourceCard
                arePrimaryActionsDisabled={arePrimaryActionsDisabled}
                metadata={metadata}
                name={name}
                stateElement={devBoxStateMessageComponent}
                statusImageElement={devBoxStatusImageElement}
                previewImageSrc={devBoxCardPreviewWin11Image}
                primaryActions={primaryActions}
                projectName={projectName}
                secondaryActions={secondaryActions}
                useTranslucentPreviewImage={useTranslucentPreviewImage}
                isShimmered={isShimmered}
                shimmeredMetadata={shimmeredDevBoxMetadata}
                cardBanner={cardBanner}
            />
        </FluentProvider>
    );
});

export default DevBoxCard;
