import { IDropdownStyles, makeStyles as legacyMakeStyles } from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { AppSemanticColor } from '../../../../../../themes/app-semantic-colors';
import { useStackStyles } from '../../../../../../themes/styles/flexbox-styles';
import { getSemanticColor } from '../../../../../../utilities/styles';
import { RegionDropdownOption } from '../../../../../add-dev-box-panel/models';
import { DropdownDividerOption } from '../../divider';
import { DropdownHeaderOption } from '../../header';
import ValueDropdown, { ValueDropdownOption, ValueDropdownWrapperProps } from '../../value-dropdown';
import { getOptionKeyForIndexedModel } from '../selectors';
import RegionDropdownItem from './region-dropdown-item';

type DropdownProps = ValueDropdownWrapperProps<RegionDropdownOption> & {
    hasOptimalRegions: boolean;
};

const messages = defineMessages({
    regionDropdownRecommendedText: {
        id: 'AddDevBoxFormRegionControls_Recommended_Text',
        defaultMessage: 'Recommended',
        description: 'Text for the recommended header in the region dropdown in the add dev box panel label',
    },
});

/**
 * Styles
 */

const useLinkStyles = legacyMakeStyles((theme) => ({
    root: {
        color: getSemanticColor(theme, AppSemanticColor.seeAllLink),
    },
}));

const useNoOptimalRegionsStyles = makeStyles({
    root: {
        paddingTop: '6px',
    },
});

const dropdownStyles: Partial<IDropdownStyles> = {
    dropdownDivider: { marginTop: '22px' },
};

/**
 * END Styles
 */

const getIsOptionHidden = (value: RegionDropdownOption): boolean => value.hidden ?? false;

const getIsOptionDisabled = (value: RegionDropdownOption): boolean => value.disabled ?? false;

export const RegionDropdown: React.FC<DropdownProps> = (props) => {
    const { options, hasOptimalRegions } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    // Style hooks
    const linkStyles = useLinkStyles();
    const stackStyles = useStackStyles();
    const noOptimalRegionsStyles = useNoOptimalRegionsStyles();

    const onRenderOption = React.useCallback(
        (value: RegionDropdownOption): JSX.Element => {
            // We get an undefined value here when we change the project after choosing a non-optimal region
            if (value === undefined) {
                return <></>;
            }

            if (value.id === 'show-all') {
                return (
                    <div className={mergeClasses(linkStyles.root, stackStyles.item)}>
                        <FormattedMessage
                            id="AddDevBoxFormRegionControls_ShowAllRegionsLink_Text"
                            defaultMessage="Show all"
                            description="Text for the show all regions link for the add dev box region form field"
                        />
                    </div>
                );
            }

            if (value.id === 'no-optimal-regions') {
                return (
                    <div className={mergeClasses(stackStyles.item, noOptimalRegionsStyles.root)}>
                        <FormattedMessage
                            id="AddDevBoxFormRegionControls_NoOptimalRegions_Text"
                            defaultMessage="No optimal regions are available for this image in your location. Contact your admin to add a region, or select another image for improved latency."
                            description="Text to tell the user that there are no optimal regions available for this image."
                        />
                    </div>
                );
            }

            return <RegionDropdownItem region={value} />;
        },
        [linkStyles, stackStyles, noOptimalRegionsStyles]
    );

    const optionsWithDivider: ValueDropdownOption<RegionDropdownOption>[] = React.useMemo(() => {
        // If there is only one option, don't insert divider.
        if (options.length > 1 && options[options.length - 1]) {
            const optionsWithDivider: ValueDropdownOption<RegionDropdownOption>[] = [
                DropdownHeaderOption('recommended', formatMessage(messages.regionDropdownRecommendedText)),
                ...options.slice(0, options.length - 1),
            ];
            optionsWithDivider.push(DropdownDividerOption('region-divider'), options[options.length - 1]);

            return optionsWithDivider;
        }

        return options;
    }, [options, formatMessage]);

    return (
        <ValueDropdown<RegionDropdownOption>
            {...props}
            onRenderOption={onRenderOption}
            options={optionsWithDivider}
            getOptionKey={getOptionKeyForIndexedModel}
            getIsOptionHidden={getIsOptionHidden}
            getIsOptionDisabled={getIsOptionDisabled}
            styles={!hasOptimalRegions ? dropdownStyles : undefined}
        />
    );
};

export default RegionDropdown;
