import { defineMessages } from 'react-intl';

export const commonMessages = defineMessages({
    testMessage: {
        id: 'TestDefinedMessage',
        description: 'Test message for loc',
        defaultMessage: 'This is the default message',
    },
    sessionExpiringMessageText: {
        id: 'SessionExpiringMessage_Text',
        defaultMessage: 'Signing out...',
        description: "Message notifying the user that they're being signed out of the portal",
    },
    welcomeMessageText: {
        id: 'WelcomeMessage_Text',
        defaultMessage: 'Welcome to Microsoft Dev Box',
        description: 'Message welcoming the user to the portal',
    },
    welcomeMessageDevPortalText: {
        id: 'WelcomeMessage_DevPortal_Text',
        defaultMessage: 'Welcome to the Microsoft developer portal',
        description: 'Message welcoming the user to the portal',
    },
    privacyLinkText: {
        id: 'Privacy_Link_Text',
        defaultMessage: 'Privacy',
        description: 'Text for link on more details about privacy',
    },
    privacyKoreanLanguageLinkText: {
        id: 'PrivacyKoreanLanguage_Link_Text',
        defaultMessage: 'Privacy Statement and location information Terms of Use',
        description:
            'Text for privacy link when app is in Korean locale. IMPORTANT: for Korean, this must be localized to exactly the string specified here: https://liquid.microsoft.com/Web/Object/Read/MS.Privacy.MPS/Requirements/03.03.01.03',
    },
    productTermsLinkText: {
        id: 'ProductTerms_Link_Text',
        defaultMessage: 'Terms of use',
        description: 'Text for link to Microsoft Dev Box product terms',
    },
});

export default commonMessages;
