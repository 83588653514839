import {
    Button,
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerHeaderTitle,
    FluentProvider,
    Link,
    makeStyles,
    mergeClasses,
} from '@fluentui/react-components';
import { Dismiss24Regular } from '@fluentui/react-icons';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useErrorDetailsPanelContext } from '../../hooks/context/panels';
import { useCurrentFluent2Theme } from '../../hooks/styling';
import { Failure } from '../../models/common';
import { DevBoxRepairOperationResult } from '../../models/dev-box';
import { useStackWithFullWidthItemStyles } from '../../themes/styles/flexbox-styles';
import { isUndefinedOrWhiteSpace } from '../../utilities/string';
import FailureCodeAndMessage from '../common/failure-message/failure-code-and-message';

export interface ErrorDetailsPanelProps {
    failure?: Failure;
    repairOperationResult?: DevBoxRepairOperationResult;
    devBoxName?: string;
    isOpen: boolean;
    linkHref?: string;
    linkText?: string;
    onDismiss: () => void;
}

const errorDetailsPanelMessages = defineMessages({
    closeButtonAriaLabel: {
        id: 'ErrorDetailsPanel_CloseButton_AriaLabel',
        defaultMessage: 'Close',
        description: 'Aria label for the close button in the "Error details" panel',
    },
    titleText: {
        id: 'ErrorDetailsPanel_Title_Text',
        defaultMessage: 'Error details',
        description: 'Title of the "Error details" panel',
    },
    devBoxNameTitleText: {
        id: 'ErrorDetailsPanel_DevBoxNameTitle_Text',
        defaultMessage: '{devBoxName} connection status',
        description: 'Title of "Error details" panel with dev box name. {devBoxName} should not be localized.',
    },
});

/**
 * Styles
 */

const useBodyStyles = makeStyles({
    root: {
        gap: '20px',
    },
});

/**
 * END Styles
 */

const ErrorDetailsPanelComponent: React.FC<ErrorDetailsPanelProps> = (props: ErrorDetailsPanelProps) => {
    const { failure, repairOperationResult, devBoxName, isOpen, linkHref, linkText, onDismiss } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    const theme = useCurrentFluent2Theme();

    // Style hooks
    const stackStyles = useStackWithFullWidthItemStyles();
    const bodyStyles = useBodyStyles();

    const titleText = React.useMemo(
        () =>
            repairOperationResult ? (
                <FormattedMessage
                    id="ErrorDetailsPanel_DevBoxNameTitle_Text"
                    defaultMessage="{devBoxName} connection status"
                    description='Title of "Error details" panel with dev box name. {devBoxName} should not be localized.'
                    values={{ devBoxName: devBoxName }}
                />
            ) : (
                <FormattedMessage
                    id="ErrorDetailsPanel_Title_Text"
                    defaultMessage="Error details"
                    description='Title of the "Error details" panel'
                />
            ),
        [repairOperationResult, devBoxName, formatMessage]
    );

    return (
        <FluentProvider theme={theme}>
            <Drawer open={isOpen} position="end" onOpenChange={onDismiss}>
                <DrawerHeader>
                    <DrawerHeaderTitle
                        action={
                            <Button
                                appearance="subtle"
                                aria-label={formatMessage(errorDetailsPanelMessages.closeButtonAriaLabel)}
                                icon={<Dismiss24Regular />}
                                onClick={onDismiss}
                            />
                        }
                    >
                        {titleText}
                    </DrawerHeaderTitle>
                </DrawerHeader>
                <DrawerBody>
                    <div className={mergeClasses(stackStyles.root, bodyStyles.root)}>
                        <div className={stackStyles.item}>
                            <FailureCodeAndMessage failure={failure} repairOperationResult={repairOperationResult} />
                        </div>

                        {!isUndefinedOrWhiteSpace(linkHref) && (
                            <div className={stackStyles.item}>
                                <Link href={linkHref} target="_blank">
                                    {!isUndefinedOrWhiteSpace(linkText) ? linkText : linkHref}
                                </Link>
                            </div>
                        )}
                    </div>
                </DrawerBody>
            </Drawer>
        </FluentProvider>
    );
};

const ErrorDetailsPanelContainer: React.FC = () => {
    // Context hooks
    const { closeSurface: closePanel, isOpen, properties } = useErrorDetailsPanelContext();

    return <ErrorDetailsPanelComponent isOpen={isOpen} onDismiss={closePanel} {...properties} />;
};

export const ErrorDetailsPanelContextWrapper: React.FC = () => {
    // Context hooks
    const { isOpen } = useErrorDetailsPanelContext();

    if (!isOpen) {
        return <></>;
    }

    return <ErrorDetailsPanelContainer />;
};

export default ErrorDetailsPanelContextWrapper;
