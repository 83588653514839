import { UnionValueMap } from '../types/union-map';

// Image names came from this query: https://dataexplorer.azure.com/dashboards/524bb6de-3dfb-4c26-84dc-f98eb310d0c1?p-_startTime=1hours&p-_endTime=now#9ef70b09-ad7c-4b26-b795-5fab924c0687
// Purposefully not localizing these display names based on them not being localized in the Azure Portal
export type ImageNames =
    | 'microsoftvisualstudio_visualstudioplustools_vs-2022-ent-general-win11-m365-gen2'
    | 'microsoftvisualstudio_windowsplustools_base-win11-gen2'
    | 'microsoftwindowsdesktop_windows-ent-cpc_win10-22h2-ent-cpc-m365'
    | 'microsoftvisualstudio_visualstudioplustools_vs-2022-pro-general-win11-m365-gen2'
    | 'microsoftwindowsdesktop_windows-ent-cpc_win11-21h2-ent-cpc-m365'
    | 'microsoftwindowsdesktop_windows-ent-cpc_win11-22h2-ent-cpc-m365'
    | 'microsoftwindowsdesktop_windows-ent-cpc_win11-21h2-ent-cpc-os'
    | 'microsoftwindowsdesktop_windows-ent-cpc_win11-23h2-ent-cpc-os'
    | 'microsoftwindowsdesktop_windows-ent-cpc_win11-23h2-ent-cpc-m365'
    | 'microsoftwindowsdesktop_windows-ent-cpc_win11-24H2-ent-cpc-m365'
    | 'microsoftvisualstudio_visualstudio2019plustools_vs-2019-pro-general-win11-m365-gen2'
    | 'microsoftvisualstudio_visualstudio2019plustools_vs-2019-ent-general-win11-m365-gen2'
    | 'microsoftwindowsdesktop_windows-ent-cpc_win11-22h2-ent-cpc-os'
    | 'microsoftwindowsdesktop_windows-ent-cpc_win10-22h2-ent-cpc-os'
    | 'microsoftvisualstudio_visualstudioplustools_vs-2022-ent-general-win10-m365-gen2'
    | 'microsoftvisualstudio_visualstudioplustools_vs-2022-pro-general-win10-m365-gen2'
    | 'microsoftwindowsdesktop_windows-ent-cpc_win10-21h2-ent-cpc-m365-g2'
    | 'microsoftvisualstudio_visualstudio2019plustools_vs-2019-pro-general-win10-m365-gen2'
    | 'microsoftwindowsdesktop_windows-ent-cpc_win10-21h2-ent-cpc-os-g2'
    | 'microsoftvisualstudio_visualstudio2019plustools_vs-2019-ent-general-win10-m365-gen2'
    | 'microsoftwindowsdesktop_windows-ent-cpc_win11-23h2-ent-cpc'
    | 'microsoftwindowsdesktop_windows-ent-cpc_win11-22h2-ent-cpc'
    | 'microsoftwindowsdesktop_windows-ent-cpc_win11-21h2-ent-cpc'
    | 'microsoftwindowsdesktop_windows-ent-cpc_win10-22h2-ent-cpc'
    | 'microsoftwindowsdesktop_windows-ent-cpc_win10-21h2-ent-cpc'
    | 'microsoftwindowsdesktop_windows-ent-cpc_win11-24h2-ent-cpc';

export type ImageDisplayName =
    | 'VS2022 Ent and M365 on Win11'
    | 'Win+ Tools on Win11'
    | 'M365 on Win10'
    | 'VS2022 Pro and M365 on Win11'
    | 'M365 on Win11 21H2'
    | 'M365 on Win11 22H2'
    | 'OS Optimized Win11 21H2'
    | 'OS Optimized Win11 23H2'
    | 'M365 on Win11 23H2'
    | 'M365 on Win11 24H2'
    | 'VS2019 Pro and M365 on Win11'
    | 'VS2019 Ent and M365 on Win11'
    | 'OS Optimized Win11 22H2'
    | 'OS Optimized Win10 22H2'
    | 'VS2022 Ent and M365 on Win10'
    | 'VS2022 Pro and M365 on Win10'
    | 'M365 on Win10 21H2'
    | 'VS2019 Pro and M365 on Win10'
    | 'OS Optimized Win10 21H2'
    | 'VS2019 Ent and M365 on Win10'
    | 'Win11 23H2'
    | 'Win11 22H2'
    | 'Win11 21H2'
    | 'Win10 22H2'
    | 'Win10 21H2'
    | 'Win11 24H2';

export const ImageDisplayNames: UnionValueMap<ImageNames, ImageDisplayName> = {
    'microsoftvisualstudio_visualstudioplustools_vs-2022-ent-general-win11-m365-gen2': 'VS2022 Ent and M365 on Win11',
    'microsoftvisualstudio_windowsplustools_base-win11-gen2': 'Win+ Tools on Win11',
    'microsoftwindowsdesktop_windows-ent-cpc_win10-22h2-ent-cpc-m365': 'M365 on Win10',
    'microsoftvisualstudio_visualstudioplustools_vs-2022-pro-general-win11-m365-gen2': 'VS2022 Pro and M365 on Win11',
    'microsoftwindowsdesktop_windows-ent-cpc_win11-21h2-ent-cpc-m365': 'M365 on Win11 21H2',
    'microsoftwindowsdesktop_windows-ent-cpc_win11-22h2-ent-cpc-m365': 'M365 on Win11 22H2',
    'microsoftwindowsdesktop_windows-ent-cpc_win11-21h2-ent-cpc-os': 'OS Optimized Win11 21H2',
    'microsoftwindowsdesktop_windows-ent-cpc_win11-23h2-ent-cpc-os': 'OS Optimized Win11 23H2',
    'microsoftwindowsdesktop_windows-ent-cpc_win11-23h2-ent-cpc-m365': 'M365 on Win11 23H2',
    'microsoftwindowsdesktop_windows-ent-cpc_win11-24H2-ent-cpc-m365': 'M365 on Win11 24H2',
    'microsoftvisualstudio_visualstudio2019plustools_vs-2019-pro-general-win11-m365-gen2':
        'VS2019 Pro and M365 on Win11',
    'microsoftvisualstudio_visualstudio2019plustools_vs-2019-ent-general-win11-m365-gen2':
        'VS2019 Ent and M365 on Win11',
    'microsoftwindowsdesktop_windows-ent-cpc_win11-22h2-ent-cpc-os': 'OS Optimized Win11 22H2',
    'microsoftwindowsdesktop_windows-ent-cpc_win10-22h2-ent-cpc-os': 'OS Optimized Win10 22H2',
    'microsoftvisualstudio_visualstudioplustools_vs-2022-ent-general-win10-m365-gen2': 'VS2022 Ent and M365 on Win10',
    'microsoftvisualstudio_visualstudioplustools_vs-2022-pro-general-win10-m365-gen2': 'VS2022 Pro and M365 on Win10',
    'microsoftwindowsdesktop_windows-ent-cpc_win10-21h2-ent-cpc-m365-g2': 'M365 on Win10 21H2',
    'microsoftvisualstudio_visualstudio2019plustools_vs-2019-pro-general-win10-m365-gen2':
        'VS2019 Pro and M365 on Win10',
    'microsoftwindowsdesktop_windows-ent-cpc_win10-21h2-ent-cpc-os-g2': 'OS Optimized Win10 21H2',
    'microsoftvisualstudio_visualstudio2019plustools_vs-2019-ent-general-win10-m365-gen2':
        'VS2019 Ent and M365 on Win10',
    'microsoftwindowsdesktop_windows-ent-cpc_win11-23h2-ent-cpc': 'Win11 23H2',
    'microsoftwindowsdesktop_windows-ent-cpc_win11-22h2-ent-cpc': 'Win11 22H2',
    'microsoftwindowsdesktop_windows-ent-cpc_win11-21h2-ent-cpc': 'Win11 21H2',
    'microsoftwindowsdesktop_windows-ent-cpc_win10-22h2-ent-cpc': 'Win10 22H2',
    'microsoftwindowsdesktop_windows-ent-cpc_win10-21h2-ent-cpc': 'Win10 21H2',
    'microsoftwindowsdesktop_windows-ent-cpc_win11-24h2-ent-cpc': 'Win11 24H2',
};
