import { FontSizes, FontWeights, Icon, Link } from '@fluentui/react';
import { Button, FluentProvider, makeStyles, mergeClasses } from '@fluentui/react-components';
import React from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { CreateDevBoxErrorCode } from '../../constants/dev-box';
import { ProductPageDevBox } from '../../constants/support-section-links';
import { useCurrentFluent2Theme } from '../../hooks/styling';
import { useThrottledCallback } from '../../hooks/use-throttled-callback';
import { Failure } from '../../models/common';
import { useHorizontalStackStyles, useStackStyles } from '../../themes/styles/flexbox-styles';
import FailureMessage from '../common/failure-message/failure-message';
import SubmitButton from '../common/submit-button';
import { CustomizationPanels, ScheduleViewModel } from './models';
import { getIsSubmitDisabled } from './selectors';

const messages = defineMessages({
    addDevBoxPanelFooterCancelButtonAriaLabel: {
        id: 'AddDevBoxPanelFooter_CancelButton_AriaLabel',
        defaultMessage: 'Cancel',
        description: 'Aria label for the add dev box panel cancel button',
    },
    addDevBoxPanelFooterSubmitButtonAriaLabel: {
        id: 'AddDevBoxPanelFooter_SubmitButton_AriaLabel',
        defaultMessage: 'Create',
        description: 'Aria label for the add dev box panel submit button',
    },
    addDevBoxPanelFooterFailureMessageIconAriaLabel: {
        id: 'AddDevBoxPanelFooter_FailureMessageIcon_AriaLabel',
        defaultMessage: 'Information icon for error message when add a dev box failed',
        description: 'Aria label for the add dev box panel failure message icon',
    },
    addDevBoxPanelFooterClockIconAriaLabel: {
        id: 'AddDevBoxPanelFooter_ClockIcon_AriaLabel',
        defaultMessage: 'Information icon for the user about the time it would take for dev box creation',
        description: 'Aria label for the add dev box creation time message',
    },
    addDevBoxPanelFooterCalendarIconAriaLabel: {
        id: 'AddDevBoxPanelFooter_CalendarIcon_AriaLabel',
        defaultMessage: 'Information icon for the user about the schedule time and frequency for dev box creation',
        description: 'Aria label for the add dev box creation schedule message',
    },
    addDevBoxPanelFooterPreviousButtonAriaLabel: {
        id: 'AddDevBoxPanelFooter_PreviousButton_AriaLabel',
        defaultMessage: 'Previous button to go to previous page of the add dev box form',
        description: 'Aria label for the add dev box panel previous button',
    },
    addDevBoxPanelFooterContinueButtonAriaLabel: {
        id: 'AddDevBoxPanelFooter_ContinueButton_AriaLabel',
        defaultMessage: 'Continue button to go to the next page of the add dev box form',
        description: 'Aria label for the add dev box panel continue button',
    },
    addDevBoxPanelFooterValidateButtonAriaLabel: {
        id: 'AddDevBoxPanelFooter_ValidateButton_AriaLabel',
        defaultMessage: 'Validate button to validate tasks',
        description: 'Aria label for the add dev box panel validate button',
    },
});

/**
 * Styles
 */

const useMessageStyles = makeStyles({
    root: {
        fontSize: FontSizes.size14,
        lineHeight: '19px',
    },
});

const useTimeMessageEmphasisStyles = makeStyles({
    root: {
        fontWeight: FontWeights.semibold,
    },
});

const useMessageIconStyles = makeStyles({
    root: {
        fontSize: FontSizes.size16,
    },
});

const useFooterMessageStyles = makeStyles({
    root: {
        gap: '8px',
    },
});

const useFooterMessagesContainerStyles = makeStyles({
    root: {
        gap: '20px',
    },
});

const useFooterStackContainerStyles = makeStyles({
    root: {
        gap: '30px',
        height: '100%',
    },
});

const useButtonsContainerStyles = makeStyles({
    root: {
        gap: '8px',
        height: '100%',
        alignItems: 'center',
    },
});

/**
 * End Styles
 */

interface AddDevBoxPanelFooterProps {
    isErrorOrFailedState: boolean;
    isSubmitting: boolean;
    isValidatingCustomizations: boolean;
    applyCustomizationsEnabled: boolean;
    isValid: boolean;
    shouldValidate: boolean;
    customizationPanelState: CustomizationPanels;
    schedule?: ScheduleViewModel;
    failure?: Failure;
    onCancelClicked: () => void;
    onSubmitClicked: () => void;
    onCustomizeClicked: () => void;
    onPreviousClicked: () => void;
    onContinueClicked: () => void;
    onValidateClicked: () => void;
    userCanCustomizeSelectedProject: boolean;
    hasCustomizationTaskDefinitions: boolean;
    hasBeenModifiedSinceLastSubmit: boolean;
}

interface AddDevBoxFailureMessageProps {
    failure: Failure | undefined;
}

const AddDevBoxFailureMessage: React.FC<AddDevBoxFailureMessageProps> = React.memo(
    (props: AddDevBoxFailureMessageProps) => {
        const { failure } = props;

        const stackStyles = useStackStyles();

        switch (failure?.code) {
            case CreateDevBoxErrorCode.GuestAccountsNotCurrentlySupported:
                //TODO: may update the defaultMessage per UX feedback to include guidance about switching tenants
                return (
                    <FailureMessage>
                        <FormattedMessage
                            id="AddDevBoxPanelFooter_GuestAccountErrorMessage_Text"
                            defaultMessage="Dev box creation failed. Azure tenant guest accounts are not currently supported. Please try another account, or contact your administrator."
                            description="Text informing users that adding a dev box resulted in a failure because they are a guest account"
                        />
                    </FailureMessage>
                );
            case CreateDevBoxErrorCode.DeprecatedSku:
                return (
                    <FailureMessage>
                        <FormattedMessage
                            id="ErrorMessage_DeprecatedSku"
                            defaultMessage="The selected pool's SKU is no longer supported."
                            description="Failure message for error code DeprecatedSku on dev box creation."
                        />
                    </FailureMessage>
                );
            default:
                return (
                    <div className={stackStyles.root}>
                        <FailureMessage failure={failure}>
                            <FormattedMessage
                                id="AddDevBoxPanelFooter_GeneralFailureMessage_Text"
                                defaultMessage="Dev box creation failed."
                                description="Text informing users that adding a dev box resulted in a failure with specific error details."
                            />
                        </FailureMessage>
                    </div>
                );
        }
    }
);

export const AddDevBoxPanelFooter: React.FC<AddDevBoxPanelFooterProps> = React.memo(
    (props: AddDevBoxPanelFooterProps) => {
        const {
            failure,
            isErrorOrFailedState,
            isSubmitting,
            isValidatingCustomizations,
            applyCustomizationsEnabled,
            shouldValidate,
            customizationPanelState,
            userCanCustomizeSelectedProject,
            isValid,
            schedule,
            onCancelClicked,
            onSubmitClicked,
            onCustomizeClicked,
            onContinueClicked,
            onPreviousClicked,
            onValidateClicked,
            hasCustomizationTaskDefinitions,
            hasBeenModifiedSinceLastSubmit,
        } = props;

        // Intl hooks
        const { formatMessage } = useIntl();
        const clockIconAriaLabel = formatMessage(messages.addDevBoxPanelFooterClockIconAriaLabel);
        const calendarIconAriaLabel = formatMessage(messages.addDevBoxPanelFooterCalendarIconAriaLabel);

        const theme = useCurrentFluent2Theme();

        // Style hooks
        const messageIconStyles = useMessageIconStyles();
        const messageStyles = useMessageStyles();
        const timeMessageEmphasisStyles = useTimeMessageEmphasisStyles();
        const horizontalStackStyles = useHorizontalStackStyles();
        const footerMessageStyles = useFooterMessageStyles();
        const stackStyles = useStackStyles();
        const footerMessagesContainerStyles = useFooterMessagesContainerStyles();
        const footerStackContainerStyles = useFooterStackContainerStyles();
        const buttonsContainerStyles = useButtonsContainerStyles();

        // Memoized data
        const timeMessageValues = React.useMemo(
            () => ({
                Link: (chunks: string) => (
                    <Link href={ProductPageDevBox} target="_blank">
                        {chunks}
                    </Link>
                ),
            }),
            [timeMessageEmphasisStyles]
        );

        const scheduleMessageValues = React.useMemo(
            () => ({
                time: schedule?.displayTime,
                frequency: schedule?.displayFrequency,
            }),
            [schedule?.displayFrequency, schedule?.displayTime]
        );

        const isCustomizeButton =
            applyCustomizationsEnabled && userCanCustomizeSelectedProject && hasCustomizationTaskDefinitions;

        const isSubmitDisabled = React.useMemo(
            () =>
                getIsSubmitDisabled(
                    failure,
                    hasBeenModifiedSinceLastSubmit,
                    isSubmitting,
                    isValid,
                    isValidatingCustomizations
                ),
            [failure, hasBeenModifiedSinceLastSubmit, isSubmitting, isValid, isValidatingCustomizations]
        );

        // Callback hooks
        // Throttle the submit button to only start the create dev box sequence once a second at most.
        // This prevents users from spamming Data Plane with bad requests.
        const throttledSubmitHandler = useThrottledCallback(
            1000,
            () => {
                onSubmitClicked();
            },
            [onSubmitClicked]
        );

        return (
            <div className={mergeClasses(stackStyles.root, footerStackContainerStyles.root)}>
                <div className={stackStyles.item}>
                    {isErrorOrFailedState && failure ? (
                        <AddDevBoxFailureMessage failure={failure} />
                    ) : (
                        <div className={mergeClasses(stackStyles.root, footerMessagesContainerStyles.root)}>
                            {schedule && (
                                <div className={stackStyles.item}>
                                    <div className={mergeClasses(horizontalStackStyles.root, footerMessageStyles.root)}>
                                        <div
                                            className={mergeClasses(horizontalStackStyles.item, messageIconStyles.root)}
                                        >
                                            <Icon aria-label={calendarIconAriaLabel} iconName="Calendar" />
                                        </div>
                                        <div className={mergeClasses(horizontalStackStyles.item, messageStyles.root)}>
                                            <FormattedMessage
                                                id="AddDevBoxPanelFooter_ScheduleMessage_Text"
                                                defaultMessage="This dev box will shut down at {time} {frequency}."
                                                description="Text informing users of when the dev box is scheduled to shut down. Do not localize {time} or {frequency}"
                                                values={scheduleMessageValues}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className={stackStyles.item}>
                                <div className={mergeClasses(horizontalStackStyles.root, footerMessageStyles.root)}>
                                    <div className={mergeClasses(horizontalStackStyles.item, messageIconStyles.root)}>
                                        <Icon aria-label={clockIconAriaLabel} iconName="Clock" />
                                    </div>
                                    <div className={mergeClasses(horizontalStackStyles.item, messageStyles.root)}>
                                        <FormattedMessage
                                            id="AddDevBoxPanelFooter_TimeMessageWithEmail_Text"
                                            defaultMessage="Dev Box creation takes 25 minutes on average, but can take up to 65 minutes. We will email you once your dev box is ready. <Link>Learn more</Link>"
                                            description="Text giving users a sense of how long adding a dev box will take."
                                            values={timeMessageValues}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className={stackStyles.item}>
                    <FluentProvider theme={theme}>
                        <div className={mergeClasses(horizontalStackStyles.root, buttonsContainerStyles.root)}>
                            {/** 
                            If we are in the customizations panel we want to render a previous button and then either a validate or a continue button.
                            If the customizations have not been validated yet and there are customizations or if there are validation errors we show the validate button.
                            Otherwise, we will show a continue button to allow you to go to the summary page 
                        */}
                            {customizationPanelState === CustomizationPanels.CustomizationFiles && (
                                <>
                                    {shouldValidate ? (
                                        <div className={horizontalStackStyles.item}>
                                            <Button
                                                appearance="primary"
                                                aria-label={formatMessage(
                                                    messages.addDevBoxPanelFooterValidateButtonAriaLabel
                                                )}
                                                onClick={onValidateClicked}
                                                disabled={!isValid || isSubmitting || isValidatingCustomizations}
                                            >
                                                <FormattedMessage
                                                    id="AddDevBoxPanelFooter_ValidateButton_Text"
                                                    defaultMessage="Validate"
                                                    description="Text for the add dev box panel validate button label"
                                                />
                                            </Button>
                                        </div>
                                    ) : (
                                        <div className={horizontalStackStyles.item}>
                                            <Button
                                                appearance="primary"
                                                aria-label={formatMessage(
                                                    messages.addDevBoxPanelFooterContinueButtonAriaLabel
                                                )}
                                                onClick={onContinueClicked}
                                                disabled={!isValid || isSubmitting || isValidatingCustomizations}
                                            >
                                                <FormattedMessage
                                                    id="AddDevBoxPanelFooter_ContinueButton_Text"
                                                    defaultMessage="Continue"
                                                    description="Text for the add dev box panel continue button label"
                                                />
                                            </Button>
                                        </div>
                                    )}
                                    <div className={horizontalStackStyles.item}>
                                        <Button
                                            aria-label={formatMessage(
                                                messages.addDevBoxPanelFooterPreviousButtonAriaLabel
                                            )}
                                            onClick={onPreviousClicked}
                                            disabled={isSubmitting || isValidatingCustomizations}
                                        >
                                            <FormattedMessage
                                                id="AddDevBoxPanelFooter_PreviousButton_Text"
                                                defaultMessage="< Previous"
                                                description="Text for the add dev box panel previous button label"
                                            />
                                        </Button>
                                    </div>
                                </>
                            )}

                            {/** 
                            If we are in the summary panel we want to render a previous button and a submit button
                            Clicking on previous will lead them back to the customizations panel and pressing submit will create the dev box 
                        */}
                            {customizationPanelState === CustomizationPanels.Summary && (
                                <>
                                    <div className={horizontalStackStyles.item}>
                                        <SubmitButton
                                            ariaLabel={formatMessage(
                                                messages.addDevBoxPanelFooterSubmitButtonAriaLabel
                                            )}
                                            isSubmitting={isSubmitting}
                                            onClick={throttledSubmitHandler}
                                            disabled={isSubmitDisabled}
                                        >
                                            <FormattedMessage
                                                id="AddDevBoxPanelFooter_SubmitButton_Text"
                                                defaultMessage="Create"
                                                description="Text for the add dev box panel submit button label"
                                            />
                                        </SubmitButton>
                                    </div>
                                    <div className={horizontalStackStyles.item}>
                                        <Button
                                            aria-label={formatMessage(
                                                messages.addDevBoxPanelFooterPreviousButtonAriaLabel
                                            )}
                                            onClick={onPreviousClicked}
                                            disabled={isSubmitting || isValidatingCustomizations}
                                        >
                                            <FormattedMessage
                                                id="AddDevBoxPanelFooter_PreviousButton_Text"
                                                defaultMessage="< Previous"
                                                description="Text for the add dev box panel previous button label"
                                            />
                                        </Button>
                                    </div>
                                </>
                            )}

                            {/** 
                            If we are not in the summary panel or the customizations panel that means we're in the basic add dev box panel.
                            In this case we want to render a cancel button and then either a continue or a submit button.
                            If the apply customizations checkbox is checked then we will render the continue button to lead to the customizations panel
                            otherwise we will just show a submit button to create the dev box with no customizations 
                        */}
                            {customizationPanelState === CustomizationPanels.None && (
                                <>
                                    {isCustomizeButton ? (
                                        <div className={horizontalStackStyles.item}>
                                            <Button
                                                appearance="primary"
                                                aria-label={formatMessage(
                                                    messages.addDevBoxPanelFooterContinueButtonAriaLabel
                                                )}
                                                onClick={onCustomizeClicked}
                                                disabled={!isValid || isSubmitting}
                                            >
                                                <FormattedMessage
                                                    id="AddDevBoxPanelFooter_ContinueButton_Text"
                                                    defaultMessage="Continue"
                                                    description="Text for the add dev box panel continue button label"
                                                />
                                            </Button>
                                        </div>
                                    ) : (
                                        <div className={horizontalStackStyles.item}>
                                            <SubmitButton
                                                id="AddDevBoxPanelFooter_SubmitButton"
                                                ariaLabel={formatMessage(
                                                    messages.addDevBoxPanelFooterSubmitButtonAriaLabel
                                                )}
                                                isSubmitting={isSubmitting}
                                                onClick={throttledSubmitHandler}
                                                disabled={isSubmitDisabled}
                                            >
                                                <FormattedMessage
                                                    id="AddDevBoxPanelFooter_SubmitButton_Text"
                                                    defaultMessage="Create"
                                                    description="Text for the add dev box panel submit button label"
                                                />
                                            </SubmitButton>
                                        </div>
                                    )}

                                    <div className={horizontalStackStyles.item}>
                                        <Button
                                            aria-label={formatMessage(
                                                messages.addDevBoxPanelFooterCancelButtonAriaLabel
                                            )}
                                            onClick={onCancelClicked}
                                            disabled={isSubmitting}
                                        >
                                            <FormattedMessage
                                                id="AddDevBoxPanelFooter_CancelButton_Text"
                                                defaultMessage="Cancel"
                                                description="Text for the add dev box panel cancel button label"
                                            />
                                        </Button>
                                    </div>
                                </>
                            )}
                        </div>
                    </FluentProvider>
                </div>
            </div>
        );
    }
);

export default AddDevBoxPanelFooter;
