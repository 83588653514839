import { Button, Spinner } from '@fluentui/react-components';
import * as React from 'react';

export type SubmitButtonProps = React.PropsWithChildren<{
    id?: string;
    isSubmitting: boolean;
    ariaLabel: string;
    onClick: () => void;
    disabled: boolean;
}>;

export const SubmitButton: React.FC<SubmitButtonProps> = (props: SubmitButtonProps) => {
    const { isSubmitting, disabled, ariaLabel, onClick, children, id } = props;

    return (
        <Button
            id={id}
            disabled={disabled || isSubmitting}
            aria-label={ariaLabel}
            onClick={onClick}
            appearance="primary"
        >
            {isSubmitting ? <Spinner size="tiny" /> : children}
        </Button>
    );
};

export default SubmitButton;
