import { MessageBarType } from '@fluentui/react';
import { ResourceCardErrorSeverity } from './models';

export const getMessageBarTypeForSeverity = (severity: ResourceCardErrorSeverity): number => {
    switch (severity) {
        case ResourceCardErrorSeverity.High:
            return MessageBarType.severeWarning;
        case ResourceCardErrorSeverity.Med:
            return MessageBarType.error;

        case ResourceCardErrorSeverity.Low:
        default:
            return MessageBarType.warning;
    }
};
