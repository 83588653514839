import {
    FluentProvider,
    makeStyles,
    Menu,
    MenuButton,
    MenuList,
    MenuPopover,
    MenuTrigger,
    Tooltip,
} from '@fluentui/react-components';
import { MoreVertical20Regular } from '@fluentui/react-icons';
import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useCurrentFluent2Theme } from '../../hooks/styling';

interface SecondaryActionsButtonProps {
    actions: JSX.Element[];
    disabled?: boolean;
    resourceName: string;
}

const messages = defineMessages({
    buttonAriaLabel: {
        id: 'SecondaryActionsButton_Button_AriaLabel',
        defaultMessage: '{resourceName} actions',
        description: 'Aria label for actions button.',
    },
    buttonText: {
        id: 'SecondaryActionsButton_Button_Text',
        defaultMessage: 'Actions',
        description: 'Text for actions button.',
    },
});

/**
 * Styles
 */

const useRootStyles = makeStyles({
    button: {
        border: 'none',
    },
});

/**
 * END Styles
 */

export const SecondaryActionsButton: React.FC<SecondaryActionsButtonProps> = React.memo(
    (props: SecondaryActionsButtonProps) => {
        const { disabled, resourceName, actions } = props;

        // Intl hooks
        const { formatMessage } = useIntl();

        const theme = useCurrentFluent2Theme();

        const styles = useRootStyles();

        const ariaLabelValues = React.useMemo(() => ({ resourceName }), [resourceName]);

        return (
            <FluentProvider theme={theme}>
                <Menu>
                    <MenuTrigger>
                        <Tooltip content={formatMessage(messages.buttonText)} relationship="label" withArrow>
                            <MenuButton
                                icon={<MoreVertical20Regular />}
                                disabled={disabled}
                                aria-label={formatMessage(messages.buttonAriaLabel, ariaLabelValues)}
                                className={styles.button}
                            />
                        </Tooltip>
                    </MenuTrigger>
                    <MenuPopover>
                        <MenuList>{actions}</MenuList>
                    </MenuPopover>
                </Menu>
            </FluentProvider>
        );
    }
);

export default SecondaryActionsButton;
