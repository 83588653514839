import { FontSizes, Icon } from '@fluentui/react';
import { Button, FluentProvider, makeStyles, mergeClasses } from '@fluentui/react-components';
import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { CreateEnvironmentErrorCode } from '../../constants/environment';
import { useCurrentFluent2Theme } from '../../hooks/styling';
import { useThrottledCallback } from '../../hooks/use-throttled-callback';
import { Failure } from '../../models/common';
import { useHorizontalStackStyles, useStackStyles } from '../../themes/styles/flexbox-styles';
import { AriaLiveWrapper } from '../common/accessibility/aria-live-wrapper';
import FailureMessage from '../common/failure-message/failure-message';
import SubmitButton from '../common/submit-button';

const messages = defineMessages({
    addEnvironmentPanelFooterCancelButtonAriaLabel: {
        id: 'AddEnvironmentPanelFooter_CancelButton_AriaLabel',
        defaultMessage: 'Cancel',
        description: 'Aria label for the add environment panel cancel button',
    },
    addEnvironmentPanelFooterSubmitButtonAriaLabel: {
        id: 'AddEnvironmentPanelFooter_SubmitButton_AriaLabel',
        defaultMessage: 'Create',
        description: 'Aria label for the add environment panel submit button',
    },
    addEnvironmentPanelFooterNextButtonAriaLabel: {
        id: 'AddEnvironmentPanelFooter_NextButton_AriaLabel',
        defaultMessage: 'Next',
        description: 'Aria label for the add environment panel next button',
    },
    addEnvironmentPanelFooterInfoIconAriaLabel: {
        id: 'AddEnvironmentPanelFooter_InfoIcon_AriaLabel',
        defaultMessage: 'Information icon for the user about the environment definition not having any parameters',
        description: 'Aria label for the add environment environment definition parameters message',
    },
    addEnvironmentPanelFooterBackButtonAriaLabel: {
        id: 'AddEnvironmentPanelFooter_BackButton_AriaLabel',
        defaultMessage: 'Back',
        description: 'Aria label for the add environment panel back button',
    },
});

/**
 * Styles
 */

const useInfoIconStyles = makeStyles({
    root: {
        fontSize: FontSizes.size16,
    },
});

const useInfoMessageStyles = makeStyles({
    root: {
        fontSize: FontSizes.size14,
    },
});

const useFooterStackContainerStyles = makeStyles({
    root: {
        gap: '30px',
        height: '100%',
    },
});

const useFooterMessageContainerStyles = makeStyles({
    root: {
        gap: '8px',
    },
});

const useButtonsContainerStyles = makeStyles({
    root: {
        gap: '8px',
        alignItems: 'center',
        height: '100%',
    },
});

/**
 * End Styles
 */

interface AddEnvironmentPanelFooterProps {
    isErrorOrFailedState: boolean;
    isSubmitting: boolean;
    isValid: boolean;
    hasParameters: boolean;
    isParametersPanel: boolean;
    failure?: Failure;
    hasSelectedEnvironmentDefinition: boolean;
    onCancelClicked: () => void;
    onSubmitClicked: () => void;
    onNextClicked: () => void;
    onBackClicked: () => void;
}

interface AddEnvironmentFailureMessageProps {
    failure: Failure | undefined;
}

const AddEnvironmentFailureMessage: React.FC<AddEnvironmentFailureMessageProps> = (
    props: AddEnvironmentFailureMessageProps
) => {
    const { failure } = props;

    switch (failure?.code) {
        case CreateEnvironmentErrorCode.DeploymentMonthlyRuntimeExceeded:
            return (
                <FailureMessage>
                    <FormattedMessage
                        id="AddEnvironmentPanelFooter_MonthlyRuntimeExceeded_Text"
                        defaultMessage="The runtime limit for this project has been exceeded, so new environments cannot be created. Please contact your administrator to request a limit increase."
                        description="Text informing users that their environment creation failed because their subscription's monthly runtime quota has been exceeded."
                    />
                </FailureMessage>
            );
        default:
            return (
                <FailureMessage failure={failure}>
                    <FormattedMessage
                        id="AddEnvironmentPanelFooter_GeneralErrorMessage_Text"
                        defaultMessage="Environment creation failed."
                        description="Text informing users that adding an environment resulted in a failure with specific error details."
                    />
                </FailureMessage>
            );
    }
};

export const AddEnvironmentPanelFooter: React.FC<AddEnvironmentPanelFooterProps> = (
    props: AddEnvironmentPanelFooterProps
) => {
    const {
        failure,
        isErrorOrFailedState,
        isSubmitting,
        isValid,
        hasParameters,
        isParametersPanel,
        onCancelClicked,
        onSubmitClicked,
        onNextClicked,
        onBackClicked,
        hasSelectedEnvironmentDefinition,
    } = props;

    // Intl hooks
    const { formatMessage } = useIntl();
    const infoIconAriaLabel = formatMessage(messages.addEnvironmentPanelFooterInfoIconAriaLabel);

    const theme = useCurrentFluent2Theme();

    // Style hooks
    const infoIconStyles = useInfoIconStyles();
    const infoMessageStyles = useInfoMessageStyles();
    const stackStyles = useStackStyles();
    const horizontalStackStyles = useHorizontalStackStyles();
    const footerStackContainerStyles = useFooterStackContainerStyles();
    const footerMessageContainerStyles = useFooterMessageContainerStyles();
    const buttonsContainerStyles = useButtonsContainerStyles();

    // Callback hooks
    // Throttle the submit button to only start the create environment sequence once a second at most.
    // This prevents users from spamming Data Plane with bad requests.
    const throttledSubmitHandler = useThrottledCallback(
        1000,
        () => {
            onSubmitClicked();
        },
        [onSubmitClicked]
    );

    return (
        <div className={mergeClasses(stackStyles.root, footerStackContainerStyles.root)}>
            {isErrorOrFailedState && failure && (
                <div className={stackStyles.item}>
                    <AddEnvironmentFailureMessage failure={failure} />
                </div>
            )}
            {!isErrorOrFailedState && hasSelectedEnvironmentDefinition && !hasParameters && (
                <div className={stackStyles.item}>
                    <div className={mergeClasses(horizontalStackStyles.root, footerMessageContainerStyles.root)}>
                        <div className={mergeClasses(horizontalStackStyles.item, infoIconStyles.root)}>
                            <Icon aria-label={infoIconAriaLabel} iconName="Info" />
                        </div>
                        <div className={mergeClasses(horizontalStackStyles.item, infoMessageStyles.root)}>
                            <AriaLiveWrapper>
                                <FormattedMessage
                                    id="AddEnvironmentPanelFooter_InfoMessage_Text"
                                    defaultMessage="This environment definition requires no parameter inputs, and will be created immediately."
                                    description="Text informing users that the environment definition has no parameters."
                                />
                            </AriaLiveWrapper>
                        </div>
                    </div>
                </div>
            )}
            <div className={stackStyles.item}>
                <FluentProvider theme={theme}>
                    <div className={mergeClasses(horizontalStackStyles.root, buttonsContainerStyles.root)}>
                        <div className={horizontalStackStyles.item}>
                            {hasSelectedEnvironmentDefinition && hasParameters && !isParametersPanel ? (
                                <SubmitButton
                                    ariaLabel={formatMessage(messages.addEnvironmentPanelFooterNextButtonAriaLabel)}
                                    isSubmitting={isSubmitting}
                                    onClick={onNextClicked}
                                    disabled={!isValid || isSubmitting}
                                >
                                    <FormattedMessage
                                        id="AddEnvironmentPanelFooter_NextButton_Text"
                                        defaultMessage="Next"
                                        description="Text for the add environment panel next button label"
                                    />
                                </SubmitButton>
                            ) : (
                                <SubmitButton
                                    ariaLabel={formatMessage(messages.addEnvironmentPanelFooterSubmitButtonAriaLabel)}
                                    isSubmitting={isSubmitting}
                                    onClick={throttledSubmitHandler}
                                    disabled={!isValid || isSubmitting}
                                >
                                    <FormattedMessage
                                        id="AddEnvironmentPanelFooter_SubmitButton_Text"
                                        defaultMessage="Create"
                                        description="Text for the add environment panel submit button label"
                                    />
                                </SubmitButton>
                            )}
                        </div>
                        {isParametersPanel && (
                            <div className={horizontalStackStyles.item}>
                                <Button
                                    aria-label={formatMessage(messages.addEnvironmentPanelFooterBackButtonAriaLabel)}
                                    onClick={onBackClicked}
                                    disabled={isSubmitting}
                                >
                                    <FormattedMessage
                                        id="AddEnvironmentPanelFooter_BackButton_Text"
                                        defaultMessage="Back"
                                        description="Text for the add environment panel back button label"
                                    />
                                </Button>
                            </div>
                        )}
                        <div className={horizontalStackStyles.item}>
                            <Button
                                aria-label={formatMessage(messages.addEnvironmentPanelFooterCancelButtonAriaLabel)}
                                onClick={onCancelClicked}
                                disabled={isSubmitting}
                            >
                                <FormattedMessage
                                    id="AddEnvironmentPanelFooter_CancelButton_Text"
                                    defaultMessage="Cancel"
                                    description="Text for the add environment panel cancel button label"
                                />
                            </Button>
                        </div>
                    </div>
                </FluentProvider>
            </div>
        </div>
    );
};

export default AddEnvironmentPanelFooter;
