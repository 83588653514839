import { createSelector } from 'reselect';
import { FeatureFlagName } from '../../../../constants/features';
import { isFeatureFlagEnabled } from '../../../../utilities/features';
import { size } from '../../../../utilities/serializable-map';
import { getIsSingleDevCenterMode } from '../../../../utilities/single-dev-center';
import { StoreStateSelector, isStatusTerminal } from '../../common';
import {
    getStatusForDiscoverProjectEnvironmentTypePermissions,
    getStatusForDiscoverProjectPermissions,
} from '../../permission-selectors';
import {
    getHasProjectEnvironmentTypesFromDataplaneAuthorizedForEnvironmentRead,
    getHasProjectEnvironmentTypesFromDataplaneAuthorizedForEnvironmentWrite,
    getStatusForDiscoverProjectEnvironmentTypesFromDataplane,
} from '../../project-environment-type-from-dataplane-selectors';
import {
    getHasProjectEnvironmentTypesAuthorizedForEnvironmentRead,
    getHasProjectEnvironmentTypesAuthorizedForEnvironmentWrite,
    getStatusForDiscoverProjectEnvironmentTypes,
} from '../../project-environment-type-selectors';
import {
    getHasProjectsFromDiscoveryServiceAuthorizedForDevBoxCreate,
    getHasProjectsFromDiscoveryServiceAuthorizedForEnvironmentWrite,
    getProjectsFromDiscoveryServiceAuthorizedForDevBoxRead,
    getProjectsFromDiscoveryServiceAuthorizedForEnvironmentRead,
    getStatusForListProjectsFromDiscoveryService,
} from '../../project-from-discovery-service-selectors';
import {
    getHasProjectsAuthorizedForDevBoxCreate,
    getHasProjectsAuthorizedForEnvironmentWrite,
    getProjectsAuthorizedForDevBoxReadByDataPlaneId,
    getProjectsAuthorizedForEnvironmentReadByDataPlaneId,
} from '../../project-selectors';
import { getStatusForLoadControlPlaneResources } from '../../sub-applications/home-selectors';
import { getDevBoxUserStateForSingleDevCenterMode } from '../../sub-applications/single-dev-center-selectors';
import { ResourceUserState } from './models';

/**
 * Composed selectors
 */

const getIsUnauthorizedState = createSelector(
    [
        getStatusForListProjectsFromDiscoveryService,
        getStatusForLoadControlPlaneResources,
        getStatusForDiscoverProjectPermissions,
    ],
    (
        statusForListProjectsFromDiscoveryService,
        statusForLoadControlPlaneResources,
        statusForDiscoverProjectPermissions
    ) => {
        return isFeatureFlagEnabled(FeatureFlagName.EnableDiscoveryService)
            ? !isStatusTerminal(statusForListProjectsFromDiscoveryService)
            : !isStatusTerminal(statusForLoadControlPlaneResources) ||
                  !isStatusTerminal(statusForDiscoverProjectPermissions);
    }
);

export const getDevBoxUserState: StoreStateSelector<ResourceUserState> = createSelector(
    [
        getProjectsAuthorizedForDevBoxReadByDataPlaneId,
        getProjectsFromDiscoveryServiceAuthorizedForDevBoxRead,
        getHasProjectsAuthorizedForDevBoxCreate,
        getHasProjectsFromDiscoveryServiceAuthorizedForDevBoxCreate,
        getIsSingleDevCenterMode,
        getDevBoxUserStateForSingleDevCenterMode,
        getIsUnauthorizedState,
    ],
    (
        projectsAuthorizedForDevBoxRead,
        projectsFromDiscoveryServiceAuthorizedForDevBoxRead,
        hasProjectsAuthorizedForDevBoxCreate,
        hasProjectsFromDiscoveryServiceAuthorizedForDevBoxCreate,
        isSingleDevCenterMode,
        devBoxUserStateForSingleDevCenterMode,
        isUnauthorizedState
    ) => {
        if (isSingleDevCenterMode) {
            return devBoxUserStateForSingleDevCenterMode;
        }

        const isDiscoveryFeatureFlagEnabled = isFeatureFlagEnabled(FeatureFlagName.EnableDiscoveryService);

        const authorizedForDevBoxCreate = isDiscoveryFeatureFlagEnabled
            ? hasProjectsFromDiscoveryServiceAuthorizedForDevBoxCreate
            : hasProjectsAuthorizedForDevBoxCreate;

        const projectsAuthorizedForDevBoxReadToUse = isDiscoveryFeatureFlagEnabled
            ? projectsFromDiscoveryServiceAuthorizedForDevBoxRead
            : projectsAuthorizedForDevBoxRead;

        // If we have dev box permissions on any project, the user is a dev box user.
        const hasDevBoxPermissions = size(projectsAuthorizedForDevBoxReadToUse) > 0 || authorizedForDevBoxCreate;

        if (isUnauthorizedState) {
            return ResourceUserState.Unknown;
        }

        if (hasDevBoxPermissions) {
            return ResourceUserState.IsUser;
        }

        // All data is loaded and no conditions are satisfied, user is not a devbox user.
        return ResourceUserState.IsNotUser;
    }
);

export const getEnvironmentUserState: StoreStateSelector<ResourceUserState> = createSelector(
    [
        getProjectsAuthorizedForEnvironmentReadByDataPlaneId,
        getProjectsFromDiscoveryServiceAuthorizedForEnvironmentRead,
        getHasProjectsAuthorizedForEnvironmentWrite,
        getHasProjectsFromDiscoveryServiceAuthorizedForEnvironmentWrite,
        getStatusForDiscoverProjectEnvironmentTypes,
        getStatusForDiscoverProjectEnvironmentTypePermissions,
        getHasProjectEnvironmentTypesAuthorizedForEnvironmentRead,
        getHasProjectEnvironmentTypesAuthorizedForEnvironmentWrite,
        getHasProjectEnvironmentTypesFromDataplaneAuthorizedForEnvironmentRead,
        getHasProjectEnvironmentTypesFromDataplaneAuthorizedForEnvironmentWrite,

        getIsSingleDevCenterMode,
        getStatusForDiscoverProjectEnvironmentTypesFromDataplane,
        getIsUnauthorizedState,
    ],
    (
        projectsAuthorizedForEnvironmentRead,
        projectsFromDiscoveryServiceAuthorizedForEnvironmentRead,
        hasProjectsAuthorizedForEnvironmentCreate,
        hasProjectsFromDiscoveryServiceAuthorizedForEnvironmentCreate,
        statusForDiscoverProjectEnvironmentTypes,
        statusForDiscoverProjectEnvironmentTypePermissions,
        hasProjectEnvironmentTypesAuthorizedForEnvironmentRead,
        hasProjectEnvironmentTypesAuthorizedForEnvironmentWrite,
        hasProjectEnvironmentTypesFromDataplaneAuthorizedForEnvironmentRead,
        hasProjectEnvironmentTypesFromDataplaneAuthorizedForEnvironmentWrite,
        isSingleDevCenterMode,
        statusForDiscoverProjectEnvironmentTypesFromDataplane,
        isUnauthorizedState
    ) => {
        // We only display dev boxes, not environments, in single dev center mode
        if (isSingleDevCenterMode) {
            return ResourceUserState.IsNotUser;
        }

        const isDiscoveryFeatureFlagEnabled = isFeatureFlagEnabled(FeatureFlagName.EnableDiscoveryService);

        const authorizedStateForProjects = isDiscoveryFeatureFlagEnabled
            ? size(projectsFromDiscoveryServiceAuthorizedForEnvironmentRead) > 0 ||
              hasProjectsFromDiscoveryServiceAuthorizedForEnvironmentCreate
            : size(projectsAuthorizedForEnvironmentRead) > 0 || hasProjectsAuthorizedForEnvironmentCreate;

        const unknownState = isDiscoveryFeatureFlagEnabled
            ? !isStatusTerminal(statusForDiscoverProjectEnvironmentTypesFromDataplane)
            : !isStatusTerminal(statusForDiscoverProjectEnvironmentTypes) ||
              !isStatusTerminal(statusForDiscoverProjectEnvironmentTypePermissions);

        const authorizedStateForProjectEnvironmentTypes = isDiscoveryFeatureFlagEnabled
            ? hasProjectEnvironmentTypesFromDataplaneAuthorizedForEnvironmentRead ||
              hasProjectEnvironmentTypesFromDataplaneAuthorizedForEnvironmentWrite
            : hasProjectEnvironmentTypesAuthorizedForEnvironmentRead ||
              hasProjectEnvironmentTypesAuthorizedForEnvironmentWrite;

        if (isUnauthorizedState) {
            return ResourceUserState.Unknown;
        }

        // If we have environment permissions on any project, the user is an environment user.
        if (authorizedStateForProjects) {
            return ResourceUserState.IsUser;
        }

        // If we have environment permissions on any environment types, the user is an environment user.
        if (unknownState) {
            return ResourceUserState.Unknown;
        }

        if (authorizedStateForProjectEnvironmentTypes) {
            return ResourceUserState.IsUser;
        }

        // All data is loaded and no conditions are satisfied, user is not an environments user.
        return ResourceUserState.IsNotUser;
    }
);
