import { ICalloutProps, TooltipHost, TooltipOverflowMode, makeStyles as legacyMakeStyles } from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHorizontalStackStyles, useStackStyles } from '../../../../../../themes/styles/flexbox-styles';
import { removeSpaces } from '../../../../../../utilities/string';
import { getSemanticColor } from '../../../../../../utilities/styles';
import { PoolViewModel } from '../../../../../add-dev-box-panel/models';

interface PoolDropdownItemProps {
    pool: PoolViewModel;
}

/**
 * Styles
 */

const useDropdownNameContainerStyles = makeStyles({
    root: {
        paddingTop: '6px',
    },
});

const useDropdownOptionContainerStyles = makeStyles({
    root: {
        height: '55px',
        width: '100%',
    },
});

const usePoolMetaDataContainerStyles = legacyMakeStyles((theme) => ({
    root: {
        color: getSemanticColor(theme, 'addDevBoxPanelPoolOptionMetaDataText'),
        paddingTop: '4px',
    },
}));

const useTooltipHostStyles = makeStyles({
    root: {
        display: 'block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
});

const usePoolMetaDataStyles = makeStyles({
    root: {
        gap: '10px',
    },
});

const tooltipCalloutProps: ICalloutProps = { gapSpace: 0 };

/**
 * End Styles
 */

export const PoolDropdownItem: React.FC<PoolDropdownItemProps> = (props: PoolDropdownItemProps) => {
    const { pool } = props;
    const { cpuCount, memoryInGb, diskSizeInGb, region, displayName: poolName } = pool;

    // Intl hooks
    const { formatNumber } = useIntl();

    // Style hooks
    const dropdownNameContainerStyles = useDropdownNameContainerStyles();
    const dropdownOptionContainerStyles = useDropdownOptionContainerStyles();
    const poolMetaDataContainerStyles = usePoolMetaDataContainerStyles();
    const tooltipHostStyles = useTooltipHostStyles();
    const stackStyles = useStackStyles();
    const horizontalStackStyles = useHorizontalStackStyles();
    const poolMetadataStyles = usePoolMetaDataStyles();

    const poolNameValues = React.useMemo(() => ({ poolName, region }), [poolName, region]);

    const poolNameContent = (
        <FormattedMessage
            id="AddDevBoxPoolDropdownItem_Location_Text"
            defaultMessage="{poolName} ({region})"
            description="Text informing user of the pool name and location. {poolName} and {region} should not be localized."
            values={poolNameValues}
        />
    );

    return (
        <div className={mergeClasses(stackStyles.root, dropdownOptionContainerStyles.root)}>
            <div
                id="PoolDropdownItem_Text"
                className={mergeClasses(stackStyles.item, dropdownNameContainerStyles.root)}
            >
                <TooltipHost
                    calloutProps={tooltipCalloutProps}
                    content={poolNameContent}
                    overflowMode={TooltipOverflowMode.Self}
                    styles={tooltipHostStyles}
                >
                    {poolNameContent}
                </TooltipHost>
            </div>
            <div className={mergeClasses(stackStyles.item, poolMetaDataContainerStyles.root)}>
                <div className={mergeClasses(horizontalStackStyles.root, poolMetadataStyles.root)}>
                    <div className={horizontalStackStyles.item}>
                        <FormattedMessage
                            id="AddDevBoxPoolDropdownItem_CpuCount_Text"
                            defaultMessage="{cpuCount} vCPU"
                            description="Text informing user of how many cores are assigned to a dev box. {cpuCount} should not be localized."
                            values={{ cpuCount: formatNumber(cpuCount) }}
                        />
                    </div>
                    <div className={horizontalStackStyles.item}>
                        <FormattedMessage
                            id="AddDevBoxPoolDropdownItem_MemoryInGb_Text"
                            defaultMessage="{memoryInGb} RAM"
                            description="Text informing user of how much memory a dev box has. {memoryInGb} should not be localized."
                            values={{
                                // Note: using removeSpaces to convert "__ GB" -> "__GB"
                                memoryInGb: removeSpaces(formatNumber(memoryInGb, { style: 'unit', unit: 'gigabyte' })),
                            }}
                        />
                    </div>
                    <div className={horizontalStackStyles.item}>
                        <FormattedMessage
                            id="AddDevBoxPoolDropdownItem_DiskSizeInGb_Text"
                            defaultMessage="{diskSizeInGb} Storage"
                            description="Text informing user of how much memory a dev box has. {diskSizeInGb} should not be localized."
                            values={{
                                // Note: using removeSpaces to convert "__ GB" -> "__GB"
                                diskSizeInGb: removeSpaces(
                                    formatNumber(diskSizeInGb, { style: 'unit', unit: 'gigabyte' })
                                ),
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PoolDropdownItem;
