import { FeatureFlagSet } from '../../models/features';
import { UnionMap } from '../../types/union-map';
import { createInitializer } from '../../utilities/initializer';

export interface DevMenuFormFieldData {
    enabled: boolean;
    frozen: boolean;
}

export const DevMenuFormFieldData = createInitializer<DevMenuFormFieldData>({
    enabled: false,
    frozen: false,
});

export const DevMenuFormFieldName: UnionMap<Uncapitalize<keyof FeatureFlagSet>> = {
    enableBetaDataPlane: 'enableBetaDataPlane',
    underDevelopment: 'underDevelopment',
    useNonPreauthorizedScopes: 'useNonPreauthorizedScopes',
    logVerbose: 'logVerbose',
    enableThemeModeToggle: 'enableThemeModeToggle',
    singleDevCenterMode: 'singleDevCenterMode',
    displayNames: 'displayNames',
    roundTripTime: 'roundTripTime',
    enableDiscoveryService: 'enableDiscoveryService',
    enableIntrinsicTasksForCustomization: 'enableIntrinsicTasksForCustomization',
    enableGuestUser: 'enableGuestUser',
    enableDevBoxRestoration: 'enableDevBoxRestoration',
};

export type DevMenuFormData = { [key in Uncapitalize<keyof FeatureFlagSet>]: DevMenuFormFieldData };

export const DevMenuFormData = createInitializer<DevMenuFormData>({
    enableBetaDataPlane: DevMenuFormFieldData(),
    underDevelopment: DevMenuFormFieldData(),
    useNonPreauthorizedScopes: DevMenuFormFieldData(),
    logVerbose: DevMenuFormFieldData(),
    enableThemeModeToggle: DevMenuFormFieldData(),
    singleDevCenterMode: DevMenuFormFieldData(),
    displayNames: DevMenuFormFieldData(),
    roundTripTime: DevMenuFormFieldData(),
    enableDiscoveryService: DevMenuFormFieldData(),
    enableIntrinsicTasksForCustomization: DevMenuFormFieldData(),
    enableGuestUser: DevMenuFormFieldData(),
    enableDevBoxRestoration: DevMenuFormFieldData(),
});
