import {
    FluentProvider,
    makeStyles,
    Menu,
    MenuButtonProps,
    MenuList,
    MenuPopover,
    MenuTrigger,
    SplitButton,
} from '@fluentui/react-components';
import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useCurrentFluent2Theme } from '../../hooks/styling';

interface PrimaryActionsButtonProps {
    actions: JSX.Element[];
    disabled?: boolean;
}

const messages = defineMessages({
    splitButtonAriaLabel: {
        id: 'PrimaryActionsButton_SplitButton_AriaLabel',
        defaultMessage: 'See more actions',
        description: 'Aria label for dropdown button in primary actions split button for dev box cards',
    },
});

/**
 * Styles
 */

const useRootStyles = makeStyles({
    button: {
        borderLeft: 'none',
        borderTop: 'none',
        borderBottom: 'none',
    },
    menuButton: {
        borderRight: 'none',
        borderTop: 'none',
        borderBottom: 'none',
    },
});

/**
 * END Styles
 */

export const PrimaryActionsButton: React.FC<PrimaryActionsButtonProps> = React.memo(
    (props: PrimaryActionsButtonProps) => {
        const { actions, disabled } = props;

        // Intl hooks
        const { formatMessage } = useIntl();

        const theme = useCurrentFluent2Theme();
        const styles = useRootStyles();

        // Memoized data
        const { ariaLabel, icon, onClick, children } = React.useMemo(() => actions[0].props, [actions]);

        const primaryActionButtonProps = {
            onClick,
            className: styles.button,
        };

        const menuButtonProps = {
            className: styles.menuButton,
            ariaLabel: formatMessage(messages.splitButtonAriaLabel),
        };

        return (
            <FluentProvider theme={theme}>
                <Menu positioning="below-end">
                    <MenuTrigger>
                        {(triggerProps: MenuButtonProps) => (
                            <SplitButton
                                icon={icon}
                                disabled={disabled}
                                aria-label={ariaLabel}
                                primaryActionButton={primaryActionButtonProps}
                                menuButton={{ ...triggerProps, ...menuButtonProps }}
                            >
                                {children}
                            </SplitButton>
                        )}
                    </MenuTrigger>
                    <MenuPopover>
                        <MenuList>{actions}</MenuList>
                    </MenuPopover>
                </Menu>
            </FluentProvider>
        );
    }
);

export default PrimaryActionsButton;
