import {
    TeachingPopover,
    TeachingPopoverBody,
    TeachingPopoverFooter,
    TeachingPopoverHeader,
    TeachingPopoverSurface,
    TeachingPopoverTitle,
} from '@fluentui/react-components';
import { MegaphoneLoud24Regular } from '@fluentui/react-icons';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

interface TeachingPopoverForDevboxCardProps {
    onConnectViaAppTeachBubbleDismiss: () => void;
    onConnectViaAppTeachingPopoverForWelcomeTourDismiss: () => void;
    onConnectViaAppTeachingPopoverForWelcomeTourSkip: () => void;
    onDevboxSecondaryActionsTeachingPopoverForWelcomeTourDismiss: () => void;
    shouldShowFirstTeachingPopoverForWelcomeTour: boolean;
    shouldShowSecondTeachingPopoverForWelcomeTour: boolean;
    shouldShowConnectViaAppTeachingBubble: boolean;
    connectViaAppTeachBubbleTargetElement: Element | null;
    devBoxCardSecondaryActionsTargetElement: Element | null;
}

const messages = defineMessages({
    connectViaAppTeachBubble: {
        id: 'DevBoxCard_ConnectViaAppTeachBubble_Title',
        defaultMessage: "We've added a new way to connect",
        description: 'Title for connect-via-app teach bubble',
    },
    connectViaAppTeachingPopover_Footer_Primary_Button: {
        id: 'DevBoxCard_ConnectViaAppTeachingPopover_Footer_Primary_Button',
        defaultMessage: 'Next tip',
        description: 'Text for the connect-via-app teaching popover footer primary button',
    },
    connectViaAppTeachingPopover_Footer_Secondary_Button: {
        id: 'DevBoxCard_ConnectViaAppTeachingPopover_Footer_Secondary_Button',
        defaultMessage: 'Skip',
        description: 'Text for the connect-via-app teaching popover footer secondary button',
    },
    devboxSecondaryActions_TeachingPopover_Footer_Button: {
        id: 'DevBoxCard_DevboxSecondaryActions_TeachingPopover_Footer_Button',
        defaultMessage: 'Done',
        description: 'Text for the Dev box secondary actions teaching popover footer button',
    },
    buttonAriaLabel: {
        id: 'DevBoxCard_SecondaryActionsButton_AriaLabel',
        defaultMessage: '{resourceName} actions',
        description: 'Aria label for actions button.',
    },
    connectViaAppMenuItemAriaLabel: {
        id: 'DevBoxCard_ConnectViaAppMenuItem_AriaLabel',
        defaultMessage: 'Connect via app',
        description: 'Aria label for "Connect via app" option in the open split button for dev box cards',
    },
});

export const TeachingPopoversForDevboxCard: React.FC<TeachingPopoverForDevboxCardProps> = React.memo(
    (props: TeachingPopoverForDevboxCardProps) => {
        const {
            onConnectViaAppTeachBubbleDismiss,
            onConnectViaAppTeachingPopoverForWelcomeTourDismiss,
            onDevboxSecondaryActionsTeachingPopoverForWelcomeTourDismiss,
            shouldShowConnectViaAppTeachingBubble,
            shouldShowFirstTeachingPopoverForWelcomeTour,
            shouldShowSecondTeachingPopoverForWelcomeTour,
            connectViaAppTeachBubbleTargetElement,
            devBoxCardSecondaryActionsTargetElement,
            onConnectViaAppTeachingPopoverForWelcomeTourSkip,
        } = props;

        // Intl hooks
        const { formatMessage } = useIntl();

        // State hooks
        const [skipped, setSkipped] = React.useState(false);

        // Selector hooks
        const onConnectViaAppTeachingPopoverForWelcomeTourDismissed = React.useCallback(() => {
            onConnectViaAppTeachingPopoverForWelcomeTourDismiss();
        }, [onConnectViaAppTeachingPopoverForWelcomeTourDismiss]);

        const onConnectViaAppTeachingPopoverForWelcomeTourSkipped = React.useCallback(() => {
            onConnectViaAppTeachingPopoverForWelcomeTourSkip();
            setSkipped(true); // To not show skipped second popover unless refreshed
        }, [onConnectViaAppTeachingPopoverForWelcomeTourSkip]);

        const onDevboxSecondaryActionsTeachingPopoverForWelcomeTourDismissed = React.useCallback(() => {
            onDevboxSecondaryActionsTeachingPopoverForWelcomeTourDismiss();
        }, [onDevboxSecondaryActionsTeachingPopoverForWelcomeTourDismiss]);

        return (
            <>
                {shouldShowFirstTeachingPopoverForWelcomeTour && (
                    <TeachingPopover
                        onOpenChange={onConnectViaAppTeachingPopoverForWelcomeTourSkipped}
                        defaultOpen
                        appearance="brand"
                        positioning={{ target: connectViaAppTeachBubbleTargetElement, position: 'after' }}
                    >
                        <TeachingPopoverSurface>
                            <TeachingPopoverHeader>
                                <FormattedMessage
                                    id="DevBoxCard_ConnectViaAppTeachingPopoverForWelcomeTour_Header"
                                    defaultMessage="Tip 1 of 2"
                                    description="Header for the connect via app teaching popover for welcome tour"
                                />
                            </TeachingPopoverHeader>
                            <TeachingPopoverBody>
                                <TeachingPopoverTitle>
                                    <FormattedMessage
                                        id="DevBoxCard_ConnectViaAppTeachingPopoverForWelcomeTour_Title"
                                        defaultMessage="Connect to your dev box"
                                        description="Title for the connect via app teaching popover for welcome tour"
                                    />
                                </TeachingPopoverTitle>
                                <div>
                                    <FormattedMessage
                                        id="DevBoxCard_ConnectViaAppTeachingPopoverForWelcomeTour_Body"
                                        defaultMessage="Connect via the Windows app for the best performance"
                                        description="Body for the connect via app teaching popover for welcome tour"
                                    />
                                </div>
                            </TeachingPopoverBody>

                            <TeachingPopoverFooter
                                primary={{
                                    children: formatMessage(
                                        messages.connectViaAppTeachingPopover_Footer_Primary_Button
                                    ),
                                    onClick: onConnectViaAppTeachingPopoverForWelcomeTourDismissed,
                                }}
                                secondary={{
                                    children: formatMessage(
                                        messages.connectViaAppTeachingPopover_Footer_Secondary_Button
                                    ),
                                    onClick: onConnectViaAppTeachingPopoverForWelcomeTourSkipped,
                                }}
                            />
                        </TeachingPopoverSurface>
                    </TeachingPopover>
                )}
                {shouldShowSecondTeachingPopoverForWelcomeTour && !skipped && (
                    <TeachingPopover
                        onOpenChange={onDevboxSecondaryActionsTeachingPopoverForWelcomeTourDismissed}
                        defaultOpen
                        appearance="brand"
                        positioning={{ target: devBoxCardSecondaryActionsTargetElement, position: 'after' }}
                    >
                        <TeachingPopoverSurface>
                            <TeachingPopoverHeader>
                                <FormattedMessage
                                    id="DevBoxCard_DevboxSecondaryActionsTeachingPopoverForWelcomeTour_Header"
                                    defaultMessage="Tip 2 of 2"
                                    description="Header for the dev box secondary actions teaching popover for welcome tour"
                                />
                            </TeachingPopoverHeader>
                            <TeachingPopoverBody>
                                <TeachingPopoverTitle>
                                    <FormattedMessage
                                        id="DevBoxCard_DevboxSecondaryActionsTeachingPopoverForWelcomeTour_Title"
                                        defaultMessage="Dev box options"
                                        description="Title for the dev box secondary actions teaching popover for welcome tour"
                                    />
                                </TeachingPopoverTitle>
                                <div>
                                    <FormattedMessage
                                        id="DevBoxCard_DevboxSecondaryActionsTeachingPopoverForWelcomeTour_Body"
                                        defaultMessage="The options menu allows you to manually start and stop your dev box. You can also take a snapshot, restore from a snapshot, get more information, delete, and more."
                                        description="Body for the dev box secondary actions teaching popover for welcome tour"
                                    />
                                </div>
                            </TeachingPopoverBody>
                            <TeachingPopoverFooter
                                primary={formatMessage(messages.devboxSecondaryActions_TeachingPopover_Footer_Button)}
                            />
                        </TeachingPopoverSurface>
                    </TeachingPopover>
                )}
                {shouldShowConnectViaAppTeachingBubble && (
                    <TeachingPopover
                        onOpenChange={onConnectViaAppTeachBubbleDismiss}
                        defaultOpen
                        appearance="brand"
                        positioning={{ target: connectViaAppTeachBubbleTargetElement, position: 'after' }}
                    >
                        <TeachingPopoverSurface>
                            <TeachingPopoverHeader icon={<MegaphoneLoud24Regular />}>
                                <FormattedMessage
                                    id="DevBoxCard_ConnectViaAppPopover_Header"
                                    defaultMessage="New feature"
                                    description="Header for the connect via app popover"
                                />
                            </TeachingPopoverHeader>
                            <TeachingPopoverBody>
                                <TeachingPopoverTitle>
                                    <FormattedMessage
                                        id="DevBoxCard_ConnectViaAppPopover_Title"
                                        defaultMessage="We’ve added a new way to connect"
                                        description="Title for the connect via app popover"
                                    />
                                </TeachingPopoverTitle>
                                <FormattedMessage
                                    id="DevBoxCard_ConnectViaAppPopover_Body"
                                    defaultMessage="Use the Windows App for the best connection experience."
                                    description="Body for the connect via app popover"
                                />
                            </TeachingPopoverBody>
                        </TeachingPopoverSurface>
                    </TeachingPopover>
                )}
            </>
        );
    }
);

export default TeachingPopoversForDevboxCard;
