import { MenuItem } from '@fluentui/react-components';
import React from 'react';

type ResourceCardActionProps = React.PropsWithChildren<{
    ariaLabel: string;
    onClick: () => void;
    icon: JSX.Element;
}>;

export const ResourceCardAction: React.FC<ResourceCardActionProps> = (props) => {
    const { ariaLabel, onClick, icon, children } = props;

    return (
        <MenuItem aria-label={ariaLabel} onClick={onClick} icon={icon}>
            {children}
        </MenuItem>
    );
};
