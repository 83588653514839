import { DevBoxAction } from '../models/dev-box-action';
import { getHoursBetweenDates } from './time';

const isDevBoxScheduleSourceId = (sourceId: string): boolean => {
    // Delete Action is returned even when feature flag is disabled, it doesn't have a sourceId which leads to errors here
    if (sourceId === undefined) {
        return false;
    }

    // The source id for the dev box default schedule looks like '/projects/{projectName}/pools/{poolName}/schedules/default'
    const parts = sourceId.split('/');

    try {
        if (parts.length !== 7) {
            return false;
        }

        if (parts[5] === 'schedules' && parts[6] === 'default') {
            return true;
        }
    } catch (error) {
        console.error(error);
        return false;
    }

    return false;
};

export const isDevBoxScheduleAction = (devBoxAction: DevBoxAction): boolean =>
    devBoxAction.actionType === 'Stop' && isDevBoxScheduleSourceId(devBoxAction.sourceId);

export const isDevBoxActionWithin24HoursOfTime = (devBoxAction: DevBoxAction, time: Date): boolean => {
    const { next } = devBoxAction;

    if (!next) {
        return false;
    }

    const hoursBetweenDates = getHoursBetweenDates(time, next.scheduledTime);

    return hoursBetweenDates !== undefined && hoursBetweenDates < 24;
};
