import { FontSizes } from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import favicon from '../../content/images/favicon.ico';
import { getIsSignedIn } from '../../redux/selector/identity-selectors';
import { useCenteredHorizontalStackStyles } from '../../themes/styles/flexbox-styles';
import { isDevPortalEnvironment } from '../../utilities/app';

/**
 * Styles
 */

const useTitleRootStyles = makeStyles({
    root: {
        height: '48px',
        gap: '8px',
    },
});

const useTitleHeadingClassName = makeStyles({
    heading: {
        fontSize: FontSizes.size16,
    },
});

const useSignedInHeadingClassName = makeStyles({
    signedInHeading: {
        fontSize: FontSizes.size16,
        marginLeft: '25px',
    },
});

const useLogoStyles = makeStyles({
    root: {
        width: '20px',
        height: '20px',
    },
});

/**
 * End Styles
 */

const messages = defineMessages({
    microsoftLogoAltText: {
        id: 'TitleBar_MicrosoftLogo_AltText',
        defaultMessage: 'Microsoft logo',
        description: 'Alt text for the Microsoft Logo',
    },
});

const titleMessage = isDevPortalEnvironment ? (
    <FormattedMessage
        id="TitleBar_Title_DevPortal_Text"
        defaultMessage="Microsoft developer portal"
        description="Title of the application, used in application header. Variant for devportal prefixed hosts."
    />
) : (
    <FormattedMessage
        id="TitleBar_Title_Text"
        defaultMessage="Microsoft Dev Box"
        description="Title of the application, used in application header."
    />
);

const Title: React.FC = React.memo(() => {
    // Style hooks
    const { heading: headingClassName } = useTitleHeadingClassName();
    const { signedInHeading: signedInHeadingClassName } = useSignedInHeadingClassName();
    const titleRootStyles = useTitleRootStyles();
    const centeredHorizontalStackStyles = useCenteredHorizontalStackStyles();
    const logoStyles = useLogoStyles();

    // Application state hooks
    const isSignedIn = useSelector(getIsSignedIn);

    // Intl hooks
    const { formatMessage } = useIntl();

    return (
        <div className={mergeClasses(centeredHorizontalStackStyles.root, titleRootStyles.root)}>
            <img src={favicon} alt={formatMessage(messages.microsoftLogoAltText)} className={logoStyles.root} />
            <h1 className={mergeClasses(centeredHorizontalStackStyles.item, headingClassName)}>Microsoft</h1>
            {isSignedIn && <h1 className={signedInHeadingClassName}>{titleMessage}</h1>}
        </div>
    );
});

export default Title;
