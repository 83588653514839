import {
    Button,
    Checkbox,
    CheckboxOnChangeData,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    FluentProvider,
} from '@fluentui/react-components';
import { Dismiss24Regular } from '@fluentui/react-icons';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { DismissableContentName } from '../../constants/app';
import { Metric } from '../../constants/telemetry';
import dialogImage from '../../content/images/Illustration_Home.webp';
import { useActionCreator } from '../../hooks/action-creator';
import { useCurrentFluent2Theme } from '../../hooks/styling';
import {
    dismissContent as dismissContentActionCreator,
    setWelcomeTourStatus,
} from '../../redux/actions/application/application-action-creators';
import { useCenteredHorizontalStackStyles } from '../../themes/styles/flexbox-styles';
import { trackMetric } from '../../utilities/telemetry/channel';
import WelcomeTourDialogTeachingPopovers from './teaching-popovers';

export interface WelcomeTourDialogProps {
    showDialog: boolean;
    createNewTeachingPopoverTargetElement: Element | null;
    settingsTeachingPopoverTargetElement: Element | null;
    helpMenuTeachingPopoverTargetElement: Element | null;
}

const messages = defineMessages({
    skipButtonAriaLabel: {
        id: 'WelcomeTourDialog_SkipButton_AriaLabel',
        defaultMessage: 'Skip',
        description: 'Aria Label for the skip button to dismiss the dialog and perform no operation',
    },
    cancelButtonAriaLabel: {
        id: 'WelcomeTourDialog_ContinueButton_AriaLabel',
        defaultMessage: 'Continue',
        description: 'Aria Label for the continue button to dismiss the dialog and perform no operation',
    },
    closeButtonAriaLabel: {
        id: 'WelcomeTourDialog_CloseButton_AriaLabel',
        defaultMessage: 'Dismiss the Welcome dialog',
        description: "Aria label for the Welcome dialog's close button",
    },
    checkBoxLabel: {
        id: 'WelcomeTourDialog_Checkbox_Label',
        defaultMessage: "Don't show this again",
        description: 'Label for the checkbox to not show dialog again',
    },
});

const imageStyle: React.CSSProperties = {
    width: '123px',
    height: '123px',
};

const dialogWidth = '525px';
const dialogHeight = '285px';
const dialogStyle: React.CSSProperties = {
    width: dialogWidth,
    height: dialogHeight,
};

// This is structured different than most dialogs in our app because its open status is controlled by selector, and not on a button click
export const WelcomeTourDialog: React.FC<WelcomeTourDialogProps> = (props: WelcomeTourDialogProps) => {
    const {
        showDialog,
        createNewTeachingPopoverTargetElement,
        settingsTeachingPopoverTargetElement,
        helpMenuTeachingPopoverTargetElement,
    } = props;

    // Theming hooks
    const theme = useCurrentFluent2Theme();

    // Intl hooks
    const { formatMessage } = useIntl();

    // Action Creators
    const dismissContent = useActionCreator(dismissContentActionCreator);
    const onWelcomeTourSeenOrSkipped = useActionCreator(setWelcomeTourStatus);

    // State hooks
    const [dialogOpen, setDialogOpen] = React.useState(showDialog);
    const [dialogSkipped, setDialogSkipped] = React.useState(false);
    const [dontShowAgainChecked, setChecked] = React.useState(false);
    const handleChange = (ev: React.ChangeEvent<HTMLInputElement>, data: CheckboxOnChangeData) => {
        setChecked(Boolean(data.checked));
    };

    // Style Hooks
    const horizontalStackStyles = useCenteredHorizontalStackStyles();

    // Local storage & Callback  hooks
    const onSkipButtonClicked = React.useCallback(() => {
        setDialogOpen(false);
        setDialogSkipped(true);
        dismissContent({ content: DismissableContentName.WelcomeTourDialogDismissed });
        trackMetric(Metric.WelcomeTourSkipped, 1);
        if (dontShowAgainChecked) {
            dismissContent({ content: DismissableContentName.WelcomeTourSkippedWithNoShowAgain });
            onWelcomeTourSeenOrSkipped({ seenOrSkippedWelcomeTour: true });
            trackMetric(Metric.WelcomeTourSkippedWithNoShowAgain, 1);
        }
    }, [onWelcomeTourSeenOrSkipped, dismissContent, dontShowAgainChecked]);

    const onContinueButtonClicked = React.useCallback(() => {
        setDialogOpen(false);
        dismissContent({ content: DismissableContentName.WelcomeTourDialogDismissed });
        trackMetric(Metric.WelcomeTourContinued, 1);
        if (dontShowAgainChecked) {
            onWelcomeTourSeenOrSkipped({ seenOrSkippedWelcomeTour: true });
            trackMetric(Metric.WelcomeTourContinuedWithNoShowAgain, 1);
        }
    }, [onWelcomeTourSeenOrSkipped, dontShowAgainChecked]);

    const onCreateNewTeachingPopoverForWelcomeTourDismissed = React.useCallback(() => {
        dismissContent({ content: DismissableContentName.CreateNewTeachingPopoverForWelcomeTour });
    }, [dismissContent]);

    const onCreateNewTeachingPopoverForWelcomeTourSkipped = React.useCallback(() => {
        dismissContent({ content: DismissableContentName.CreateNewTeachingPopoverForWelcomeTourSkipped });
    }, [dismissContent]);

    const onSettingsMenuTeachingPopoverForWelcomeTourDismissed = React.useCallback(() => {
        dismissContent({ content: DismissableContentName.SettingsMenuTeachingPopoverForWelcomeTour });
    }, [dismissContent]);

    const onSettingsMenuTeachingPopoverForWelcomeTourSkipped = React.useCallback(() => {
        dismissContent({ content: DismissableContentName.SettingsMenuTeachingPopoverForWelcomeTourSkipped });
    }, [dismissContent]);

    const onHelpMenuTeachingPopoverForWelcomeTourDismissed = React.useCallback(() => {
        dismissContent({ content: DismissableContentName.HelpMenuTeachingPopoverForWelcomeTour });
    }, [dismissContent]);

    return (
        <FluentProvider theme={theme}>
            <Dialog modalType="alert" open={dialogOpen} onOpenChange={(event, data) => setDialogOpen(data.open)}>
                <DialogSurface style={dialogStyle}>
                    <DialogBody>
                        <DialogTitle
                            action={
                                <DialogTrigger action="close">
                                    <Button
                                        appearance="subtle"
                                        aria-label={formatMessage(messages.closeButtonAriaLabel)}
                                        icon={<Dismiss24Regular />}
                                        onClick={onSkipButtonClicked}
                                    />
                                </DialogTrigger>
                            }
                        >
                            <FormattedMessage
                                id="WelcomeTourDialog_Title_Text"
                                defaultMessage="Welcome to the Microsoft Developer Portal!"
                                description="Title of the welcome tour dialog"
                            />
                        </DialogTitle>
                        <DialogContent>
                            <div className={horizontalStackStyles.root}>
                                <div className={horizontalStackStyles.item}>
                                    <img src={dialogImage} alt="" style={imageStyle} />
                                </div>
                                <div className={horizontalStackStyles.item}>
                                    <FormattedMessage
                                        id="WelcomeTourDialog_Body_Text"
                                        defaultMessage="The developer portal puts creation of development resources like dev boxes and deployment
                            environments in your hands. Continue for a quick tour, or dive right in!"
                                        description="Body of the welcome tour dialog"
                                    />
                                </div>
                            </div>
                            <Checkbox
                                checked={dontShowAgainChecked}
                                onChange={handleChange}
                                label={formatMessage(messages.checkBoxLabel)}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                appearance="primary"
                                aria-label={formatMessage(messages.cancelButtonAriaLabel)}
                                onClick={onContinueButtonClicked}
                            >
                                <FormattedMessage
                                    id="WelcomeTourDialog_ContinueButton_Text"
                                    defaultMessage="Continue"
                                    description="Continue button to continue the welcome tour"
                                />
                            </Button>
                            <DialogTrigger>
                                <Button
                                    id="WelcomeTour_SkipButton"
                                    appearance="secondary"
                                    aria-label={formatMessage(messages.skipButtonAriaLabel)}
                                    onClick={onSkipButtonClicked}
                                >
                                    <FormattedMessage
                                        id="WelcomeTourDialog_SkipButton_Text"
                                        defaultMessage="Skip"
                                        description="Skip button to skip the welcome tour"
                                    />
                                </Button>
                            </DialogTrigger>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
            <WelcomeTourDialogTeachingPopovers
                dialogDismissed={!dialogOpen}
                createNewTeachingPopoverTargetElement={createNewTeachingPopoverTargetElement}
                settingsMenuTeachingPopoverTargetElement={settingsTeachingPopoverTargetElement}
                helpMenuTeachingPopoverTargetElement={helpMenuTeachingPopoverTargetElement}
                onCreateNewTeachingPopoverForWelcomeTourDismiss={onCreateNewTeachingPopoverForWelcomeTourDismissed}
                onCreateNewTeachingPopoverForWelcomeTourSkip={onCreateNewTeachingPopoverForWelcomeTourSkipped}
                onSettingsMenuTeachingPopoverForWelcomeTourDismiss={
                    onSettingsMenuTeachingPopoverForWelcomeTourDismissed
                }
                onSettingsMenuTeachingPopoverForWelcomeTourSkip={onSettingsMenuTeachingPopoverForWelcomeTourSkipped}
                onHelpMenuTeachingPopoverForWelcomeTourDismiss={onHelpMenuTeachingPopoverForWelcomeTourDismissed}
                onWelcomeTourSeenOrSkipped={onWelcomeTourSeenOrSkipped}
                dialogSkipped={dialogSkipped}
            />
        </FluentProvider>
    );
};
