import { Button, DrawerFooter, makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useHorizontalStackStyles } from '../../themes/styles/flexbox-styles';

interface DevMenuPanelFooterProps {
    isSubmitDisabled?: boolean;
    onCancelClicked: () => void;
    onSubmitClicked: () => void;
}

const messages = defineMessages({
    cancelButtonAriaLabel: {
        id: 'DevMenuPanelFooter_CancelButton_AriaLabel',
        defaultMessage: 'Cancel',
        description: 'Aria label for "Cancel" button in dev menu form',
    },
    saveButtonAriaLabel: {
        id: 'DevMenuPanelFooter_SaveButton_AriaLabel',
        defaultMessage: 'Save',
        description: 'Aria label for "Save" button in dev menu form',
    },
});

/**
 * Styles
 */

const useButtonContainerStyles = makeStyles({
    root: {
        gap: '8px',
        height: '100%',
        alignItems: 'center',
    },
});

/**
 * END Styles
 */

export const DevMenuPanelFooter: React.FC<DevMenuPanelFooterProps> = (props: DevMenuPanelFooterProps) => {
    const { isSubmitDisabled, onCancelClicked, onSubmitClicked } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    // Style hooks
    const horizontalStackStyles = useHorizontalStackStyles();
    const buttonContainerStyles = useButtonContainerStyles();

    return (
        <DrawerFooter>
            <div className={mergeClasses(horizontalStackStyles.root, buttonContainerStyles.root)}>
                <div className={horizontalStackStyles.item}>
                    <Button
                        aria-label={formatMessage(messages.saveButtonAriaLabel)}
                        disabled={isSubmitDisabled}
                        onClick={onSubmitClicked}
                        appearance="primary"
                    >
                        <FormattedMessage
                            id="DevMenuPanelFooter_SaveButton_Text"
                            defaultMessage="Save"
                            description='Text for "Save" button in dev menu form'
                        />
                    </Button>
                </div>

                <div className={horizontalStackStyles.item}>
                    <Button aria-label={formatMessage(messages.cancelButtonAriaLabel)} onClick={onCancelClicked}>
                        <FormattedMessage
                            id="DevMenuPanelFooter_CancelButton_Text"
                            defaultMessage="Cancel"
                            description='Text for "Cancel" button in dev menu form'
                        />
                    </Button>
                </div>
            </div>
        </DrawerFooter>
    );
};
