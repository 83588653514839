import { FontSizes, FontWeights } from '@fluentui/react';
import { Button, FluentProvider, makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { ResourceFilterType } from '../constants/filters';
import noResourcesFoundImage from '../content/images/Search_Empty.svg';
import { useProjectFilterContext, useResourceFilterContext } from '../hooks/context/filters';
import { useCurrentFluent2Theme } from '../hooks/styling';
import { useHorizontalStackStyles, useStackStyles } from '../themes/styles/flexbox-styles';
import { clearFiltersFromCurrentLocation } from '../utilities/filters';

interface NoResourcesFilterComponentProps {
    resetResourceFilter: (value: ResourceFilterType | undefined) => void;
    resetProjectFilter: (value: string | undefined) => void;
}

const messages = defineMessages({
    resetFiltersButtonAriaLabel: {
        id: 'NoResourcesFilter_ResetFiltersButton_AriaLabel',
        defaultMessage: 'Button for resetting filters',
        description: 'Aria label for resetting filters button',
    },
    noResourcesFoundText: {
        id: 'NoResourcesFilter_NoResourcesFound_Text',
        defaultMessage: 'No resources found',
        description: 'Message informing user that there are no resources found for the current filters',
    },
    noResourcesMatchText: {
        id: 'NoResourcesFilter_NoResourcesMatch_Text',
        defaultMessage: 'No resources match the filters that are currently applied.',
        description: 'Message informing user that the filters applied resulted in no resources',
    },
    noResourcesFilterImageAlt: {
        id: 'NoResourcesFilter_NoResourcesFilterImage_Alt',
        defaultMessage: 'No resources match filters applied',
        description: 'Alt text for "No resources found" image',
    },
});

/**
 * Styles
 */

const useNoResourcesContainerStyles = makeStyles({
    root: {
        paddingTop: '70px',
        gap: '11px',
        alignItems: 'center',
        justifyContent: 'center',
    },
});

const useDescriptionContainerStyles = makeStyles({
    root: {
        lineHeight: '20px',
        maxWidth: '432px',
        textAlign: 'left',
        height: '100%',
    },
});

// Note: using paddingTop to space StackItems as Stack will override margins
const useEmptyContainerMessageStyles = makeStyles({
    root: {
        fontSize: FontSizes.size16,
        fontWeight: FontWeights.bold,
        lineHeight: '22px',
        paddingTop: '6px',
    },
});

const useResetFiltersMessageStyles = makeStyles({
    root: {
        fontSize: FontSizes.size14,
        fontWeight: `${FontWeights.semibold} !important`,
        lineHeight: '20px',
        paddingTop: '6px',
    },
});

const useResetFiltersButtonContainerStyles = makeStyles({
    root: {
        paddingTop: '24px',
    },
});

const useButtonStackStyles = makeStyles({
    root: {
        gap: 'm',
    },
});

/**
 * End Styles
 */

export const NoResourcesFilterComponent: React.FC<NoResourcesFilterComponentProps> = React.memo(
    (props: NoResourcesFilterComponentProps) => {
        const { resetResourceFilter, resetProjectFilter } = props;

        // Intl hooks
        const { formatMessage } = useIntl();

        const theme = useCurrentFluent2Theme();

        // Style hooks
        const resetFiltersMessageStyles = useResetFiltersMessageStyles();
        const resetFiltersButtonContainerStyles = useResetFiltersButtonContainerStyles();
        const descriptionContainerStyles = useDescriptionContainerStyles();
        const emptyContainerMessageStyles = useEmptyContainerMessageStyles();
        const noResourcesContainerStyles = useNoResourcesContainerStyles();
        const horizontalStackStyles = useHorizontalStackStyles();
        const stackStyles = useStackStyles();
        const buttonStackStyles = useButtonStackStyles();

        const onReset = React.useCallback(
            () => (resetResourceFilter(undefined), resetProjectFilter(undefined), clearFiltersFromCurrentLocation()),
            [resetResourceFilter, resetProjectFilter, clearFiltersFromCurrentLocation]
        );

        return (
            <div className={mergeClasses(horizontalStackStyles.root, noResourcesContainerStyles.root)}>
                <div className={horizontalStackStyles.item}>
                    <img alt={formatMessage(messages.noResourcesFilterImageAlt)} src={noResourcesFoundImage} />
                </div>
                <div className={horizontalStackStyles.item}>
                    <div className={mergeClasses(stackStyles.root, descriptionContainerStyles.root)}>
                        <div className={mergeClasses(stackStyles.item, emptyContainerMessageStyles.root)}>
                            {formatMessage(messages.noResourcesFoundText)}
                        </div>
                        <div className={mergeClasses(stackStyles.item, resetFiltersMessageStyles.root)}>
                            {formatMessage(messages.noResourcesMatchText)}
                        </div>
                        <div className={mergeClasses(stackStyles.item, resetFiltersButtonContainerStyles.root)}>
                            <div className={mergeClasses(horizontalStackStyles.root, buttonStackStyles.root)}>
                                <FluentProvider theme={theme}>
                                    <Button
                                        aria-label={formatMessage(messages.resetFiltersButtonAriaLabel)}
                                        onClick={onReset}
                                    >
                                        <FormattedMessage
                                            id="NoResourcesFilter_ResetFiltersButton_Text"
                                            defaultMessage="Reset filters"
                                            description="Label text for the resetting filters button"
                                        />
                                    </Button>
                                </FluentProvider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
);

export const NoResourcesFilterContainer: React.FC = () => {
    // Context hooks
    const { onChange: resetResourceFilter } = useResourceFilterContext();
    const { onChange: resetProjectFilter } = useProjectFilterContext();

    return (
        <NoResourcesFilterComponent resetResourceFilter={resetResourceFilter} resetProjectFilter={resetProjectFilter} />
    );
};

export default NoResourcesFilterContainer;
