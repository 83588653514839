import { Button, FluentProvider, makeStyles, mergeClasses } from '@fluentui/react-components';
import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useCurrentFluent2Theme } from '../../hooks/styling';
import { Failure } from '../../models/common';
import { useHorizontalStackStyles, useStackStyles } from '../../themes/styles/flexbox-styles';
import FailureMessage from '../common/failure-message/failure-message';
import SubmitButton from '../common/submit-button';

const messages = defineMessages({
    redeployEnvironmentPanelFooterCancelButtonAriaLabel: {
        id: 'RedeployEnvironmentPanelFooter_CancelButton_AriaLabel',
        defaultMessage: 'Cancel button to close the redeploy environment panel',
        description: 'Aria label for the redeploy environment panel cancel button',
    },
    redeployEnvironmentPanelFooterSubmitButtonAriaLabel: {
        id: 'RedeployEnvironmentPanelFooter_SubmitButton_AriaLabel',
        defaultMessage: 'Redeploy button to submit redeployment',
        description: 'Aria label for the redeploy environment panel submit button',
    },
});

/**
 * Styles
 */

const useFooterStackContainerStyles = makeStyles({
    root: {
        gap: '30px',
        height: '100%',
    },
});

const useButtonContainerStyles = makeStyles({
    root: {
        gap: '8px',
        height: '100%',
        alignItems: 'center',
    },
});

/**
 * End Styles
 */

interface RedeployEnvironmentPanelFooterProps {
    isErrorOrFailedState: boolean;
    isSubmitting: boolean;
    isValid: boolean;
    failure?: Failure;
    onCancelClicked: () => void;
    onSubmitClicked: () => void;
}

interface RedeployEnvironmentFailureMessageProps {
    failure: Failure | undefined;
}

const RedeployEnvironmentFailureMessage: React.FC<RedeployEnvironmentFailureMessageProps> = (
    props: RedeployEnvironmentFailureMessageProps
) => {
    const { failure } = props;

    // Style hooks
    const stackStyles = useStackStyles();

    return (
        <div className={stackStyles.root}>
            <FailureMessage failure={failure}>
                <FormattedMessage
                    id="RedeployEnvironmentPanelFooter_GeneralErrorMessage_Text"
                    defaultMessage="Failed to redeploy environment."
                    description="Text informing users that redeploying their environment failed."
                />
            </FailureMessage>
        </div>
    );
};

export const RedeployEnvironmentPanelFooter: React.FC<RedeployEnvironmentPanelFooterProps> = (
    props: RedeployEnvironmentPanelFooterProps
) => {
    const { failure, isErrorOrFailedState, isSubmitting, isValid, onCancelClicked, onSubmitClicked } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    const theme = useCurrentFluent2Theme();

    // Style hooks
    const stackStyles = useStackStyles();
    const footerStackContainerStyles = useFooterStackContainerStyles();
    const horizontalStackStyles = useHorizontalStackStyles();
    const buttonContainerStyles = useButtonContainerStyles();

    return (
        <div className={mergeClasses(stackStyles.root, footerStackContainerStyles.root)}>
            {isErrorOrFailedState && failure && (
                <div className={stackStyles.item}>
                    <RedeployEnvironmentFailureMessage failure={failure} />
                </div>
            )}

            <div className={stackStyles.item}>
                <FluentProvider theme={theme}>
                    <div className={mergeClasses(horizontalStackStyles.root, buttonContainerStyles.root)}>
                        <div className={horizontalStackStyles.item}>
                            <SubmitButton
                                ariaLabel={formatMessage(messages.redeployEnvironmentPanelFooterSubmitButtonAriaLabel)}
                                isSubmitting={isSubmitting}
                                onClick={onSubmitClicked}
                                disabled={!isValid || isSubmitting}
                            >
                                <FormattedMessage
                                    id="RedeployEnvironmentPanelFooter_SubmitButton_Text"
                                    defaultMessage="Redeploy"
                                    description="Text for the redeploy environment panel submit button label"
                                />
                            </SubmitButton>
                        </div>

                        <div className={horizontalStackStyles.item}>
                            <Button
                                aria-label={formatMessage(messages.redeployEnvironmentPanelFooterCancelButtonAriaLabel)}
                                onClick={onCancelClicked}
                                disabled={isSubmitting}
                            >
                                <FormattedMessage
                                    id="RedeployEnvironmentPanelFooter_CancelButton_Text"
                                    defaultMessage="Cancel"
                                    description="Text for the redeploy environment panel cancel button label"
                                />
                            </Button>
                        </div>
                    </div>
                </FluentProvider>
            </div>
        </div>
    );
};

export default RedeployEnvironmentPanelFooter;
